import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { Auth } from 'aws-amplify';
import { FaBars, FaUser, FaSignOutAlt } from 'react-icons/fa';
import Logo from './FastReconLogo.png';
import './bmheader.css';

function Header() {
    const [user, setUser] = useState(null);
    const [showSignOut, setShowSignOut] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                setUser(currentUser);
            } catch (error) {
                
            }
        };

        fetchUser();
    }, []);

    const handleSignOut = async () => {
        try {
            await Auth.signOut({ global: true });
            window.location.reload();
        } catch (error) {
           
        }
    };

    const toggleSignOut = () => {
        setShowSignOut(!showSignOut);
    };

    return (
        <div className="header-container">
            {user && (
                <button
                    className={`header-sign-out-button ${showSignOut ? "header-sign-out-button-visible" : ""}`}
                    onClick={handleSignOut}
                >
                    <FaSignOutAlt className="header-sign-out-icon" /> Sign Out
                </button>
            )}
            <header className="header">
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div className="header-logo-container">
                        <img src={Logo} alt="Logo" className="header-logo" />
                        <h1 className="header-logo-text">FastRecon <span className="header-beta-tag">Beta</span></h1>
                    </div>
                </Link>

                {user && (
                    <div className="header-user-info-container" onClick={toggleSignOut}>
                        <div className="header-user-info">
                            <FaUser className="header-user-icon" />
                            <span>{user.attributes.email}</span>
                        </div>
                    </div>
                )}

                <Menu right customBurgerIcon={<FaBars />}>
                    <Link to="/" className="header-menu-item">Home</Link>
                    <Link to="/config/new" className="header-menu-item">Create New</Link>
                    
                    <div onClick={handleSignOut} className="header-menu-item header-sign-out-link">Sign Out</div>
                </Menu>
            </header>
        </div>
    );
}

export default Header;
