import React from 'react';
import { MdDelete } from 'react-icons/md';

// Mappings Section 

export const handleMappingChange = (fieldName, index, type, newValue, setFieldsConfig) => {
    setFieldsConfig(prevFieldsConfig => {
        const updatedFieldsConfig = { ...prevFieldsConfig };
        const mappings = updatedFieldsConfig[fieldName]?.mappings || [];
        if (mappings[index]) {
            mappings[index] = { ...mappings[index], [type]: newValue };
        }
        updatedFieldsConfig[fieldName] = { ...updatedFieldsConfig[fieldName], mappings };
        return updatedFieldsConfig;
    });
};

export const addNewRow = (fieldName, setFieldsConfig) => {
    setFieldsConfig(prevFieldsConfig => {
        const updatedFieldsConfig = { ...prevFieldsConfig };
        const mappings = [...(updatedFieldsConfig[fieldName]?.mappings || []), { key: '', value: '' }];
        updatedFieldsConfig[fieldName] = { ...updatedFieldsConfig[fieldName], mappings };
        return updatedFieldsConfig;
    });
};

export const deleteRow = (fieldName, index, setFieldsConfig) => {
    setFieldsConfig(prevFieldsConfig => {
        const updatedFieldsConfig = { ...prevFieldsConfig };
        if (updatedFieldsConfig[fieldName] && updatedFieldsConfig[fieldName].mappings) {
            const updatedMappings = updatedFieldsConfig[fieldName].mappings.filter((item, idx) => idx !== index);
            updatedFieldsConfig[fieldName] = { ...updatedFieldsConfig[fieldName], mappings: updatedMappings };
        }
        return updatedFieldsConfig;
    });
};

export const MappingsSection = ({ fieldName, fieldsConfig, handleMappingChange, addNewRow, deleteRow }) => (
    <div className="mapping-section">
        {fieldsConfig[fieldName].mappings.map((mapping, mapIndex) => (
            <div key={mapIndex} className="mapping-item">
                <input
                    type="text"
                    value={mapping.key || ''}
                    placeholder="Name in File 1"
                    onChange={(e) => handleMappingChange(fieldName, mapIndex, 'key', e.target.value)}
                />
                <input
                    type="text"
                    value={mapping.value || ''}
                    placeholder="Name in File 2"
                    onChange={(e) => handleMappingChange(fieldName, mapIndex, 'value', e.target.value)}
                />
                <button onClick={() => deleteRow(fieldName, mapIndex)}>
                    <MdDelete />
                </button>
            </div>
        ))}
        <button onClick={() => addNewRow(fieldName)}>Add Mapping</button>
    </div>
);

// Ignore Characters 

export const handleIgnoreCharacterChange = (fieldName, index, newValue, setFieldsConfig) => {
    setFieldsConfig(prevFieldsConfig => {
        const updatedFieldsConfig = { ...prevFieldsConfig };
        const ignoreCharacters = updatedFieldsConfig[fieldName]?.ignoreCharacters || [];
        if (ignoreCharacters[index] !== undefined) {
            ignoreCharacters[index] = newValue;
        }
        updatedFieldsConfig[fieldName] = { ...updatedFieldsConfig[fieldName], ignoreCharacters };
        return updatedFieldsConfig;
    });
};

export const addIgnoreCharacter = (fieldName, setFieldsConfig) => {
    setFieldsConfig(prevFieldsConfig => {
        const updatedFieldsConfig = { ...prevFieldsConfig };
        const ignoreCharacters = [...(updatedFieldsConfig[fieldName]?.ignoreCharacters || []), ''];
        updatedFieldsConfig[fieldName] = { ...updatedFieldsConfig[fieldName], ignoreCharacters };
        return updatedFieldsConfig;
    });
};

export const removeIgnoreCharacter = (fieldName, index, setFieldsConfig) => {
    setFieldsConfig(prevFieldsConfig => {
        const updatedFieldsConfig = { ...prevFieldsConfig };
        if (updatedFieldsConfig[fieldName] && updatedFieldsConfig[fieldName].ignoreCharacters) {
            const updatedIgnoreCharacters = updatedFieldsConfig[fieldName].ignoreCharacters.filter((_, idx) => idx !== index);
            updatedFieldsConfig[fieldName] = { ...updatedFieldsConfig[fieldName], ignoreCharacters: updatedIgnoreCharacters };
        }
        return updatedFieldsConfig;
    });
};


export const IgnoreCharactersSection = ({ fieldName, fieldsConfig, handleIgnoreCharacterChange, addIgnoreCharacter, removeIgnoreCharacter }) => (
    <div className="ignore-characters-section">
        {fieldsConfig[fieldName].ignoreCharacters.map((char, charIndex) => (
            <div key={charIndex} className="ignore-character-item">
                <input
                    type="text"
                    value={char}
                    onChange={(e) => handleIgnoreCharacterChange(fieldName, charIndex, e.target.value)}
                />
                <button onClick={() => removeIgnoreCharacter(fieldName, charIndex)}>
                    <MdDelete />
                </button>
            </div>
        ))}
        <button onClick={() => addIgnoreCharacter(fieldName)}>Add Ignore Character</button>
    </div>
);



//Text Transformations

export const handleTextTransformationChange = (fieldName, type, value, setFieldsConfig) => {
    setFieldsConfig(prevFieldsConfig => {
        const updatedFieldsConfig = { ...prevFieldsConfig };
        const textTransformations = updatedFieldsConfig[fieldName]?.textTransformations || {
            caseConversion: '',
            substringStart: '',
            substringEnd: '',
            beforeChar: '',
            afterChar: '',
        };
        textTransformations[type] = value;
        updatedFieldsConfig[fieldName] = { ...updatedFieldsConfig[fieldName], textTransformations };
        return updatedFieldsConfig;
    });
};

export const UppercaseLowercaseSection = ({ fieldName, fieldsConfig, handleTextTransformationChange }) => {
    return (
        <div className="text-transformation-section">
            <label>Case Conversion:</label>
            <select
                value={fieldsConfig[fieldName]?.textTransformations?.caseConversion || ''}
                onChange={(e) => handleTextTransformationChange(fieldName, 'caseConversion', e.target.value)}
            >
                <option value="">Select Conversion</option>
                <option value="uppercase">Uppercase</option>
                <option value="lowercase">Lowercase</option>
                <option value="caseInsensitive">Case Insensitive</option>
            </select>
        </div>
    );
};

export const SubstringExtractionSection = ({ fieldName, fieldsConfig, handleTextTransformationChange }) => {
    return (
        <div className="substring-extraction-section">
            <label>Substring Start (Index):</label>
            <input
                type="number"
                value={fieldsConfig[fieldName]?.textTransformations?.substringStart || ''}
                onChange={(e) => handleTextTransformationChange(fieldName, 'substringStart', e.target.value)}
            />
            <label>Substring End (Index):</label>
            <input
                type="number"
                value={fieldsConfig[fieldName]?.textTransformations?.substringEnd || ''}
                onChange={(e) => handleTextTransformationChange(fieldName, 'substringEnd', e.target.value)}
            />
                        <label>Extract After:</label>
            <input
                type="text"
                value={fieldsConfig[fieldName]?.textTransformations?.afterChar || ''}
                onChange={(e) => handleTextTransformationChange(fieldName, 'afterChar', e.target.value)}
            />

            <label>Extract Before:</label>
            <input
                type="text"
                value={fieldsConfig[fieldName]?.textTransformations?.beforeChar || ''}
                onChange={(e) => handleTextTransformationChange(fieldName, 'beforeChar', e.target.value)}
            />
          
        </div>
    );
};
