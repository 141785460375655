import React, { useState, useEffect } from 'react';

// Function to handle changes in aggregation settings
export const handleAggregationChange = (fieldName, type, value, setFieldsConfig) => {
    setFieldsConfig(prevFieldsConfig => {
        const updatedFieldsConfig = { ...prevFieldsConfig };
        const aggregation = updatedFieldsConfig[fieldName]?.aggregation || {
            method: '',
            aggregateBy: []
        };

        if (type === 'method') {
            aggregation.method = value;
        }

        updatedFieldsConfig[fieldName] = { ...updatedFieldsConfig[fieldName], aggregation };
        return updatedFieldsConfig;
    });
};

// Component to render the aggregation method input fields
export const AggregationMethodSection = ({ fieldName, fieldsConfig, handleAggregationChange }) => (
    <div className="aggregation-section">
        <label>Aggregation Method:</label>
        <select
            value={fieldsConfig[fieldName]?.aggregation?.method || ''}
            onChange={(e) => handleAggregationChange(fieldName, 'method', e.target.value)}
        >
            <option value="">Select Method</option>
            <option value="SUM">Sum</option>
            <option value="AVERAGE">Average</option>
        </select>
    </div>
);

export const AggregationComponent = ({ configData }) => {
    const [fieldsConfig, setFieldsConfig] = useState({});
    
    useEffect(() => {
        // Initialize fieldsConfig based on configData
        const initialConfig = {};
        configData.columns.forEach(column => {
            initialConfig[column.standardName] = {
                aggregation: {
                    method: '',
                    aggregateBy: []
                }
            };
        });
        setFieldsConfig(initialConfig);
    }, [configData]);

    return (
        <div>
            {configData.columns.map(column => (
                <div key={column.standardName} className="aggregation-item">
                    {fieldsConfig[column.standardName]?.showAggregation && (
                        <AggregationMethodSection
                            fieldName={column.standardName}
                            fieldsConfig={fieldsConfig}
                            handleAggregationChange={(fieldName, type, value) => handleAggregationChange(fieldName, type, value, setFieldsConfig)}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

