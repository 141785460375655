import React, { useState, useEffect } from 'react';
import { Auth, API, Storage } from 'aws-amplify';
import awsconfig from './aws-exports';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './ConfigForm.css';
import DOMPurify from 'dompurify';

API.configure(awsconfig);

const ConfigForm = () => {
const [form, setForm] = useState({
  title: '',
  type: '',
  filename1: '',
  filename2: '',
  columns: [{ standardName: '', nameInFile1: '', nameInFile2: '', dataType: '' }],
});


  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  
  const [loading, setLoading] = useState(false);
  const [existingTitles, setExistingTitles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isTitleDuplicate, setIsTitleDuplicate] = useState(false);
  const [file1Headers, setFile1Headers] = useState([]);
  const [file2Headers, setFile2Headers] = useState([]);
  const [file1DataTypes, setFile1DataTypes] = useState({});
  const [file2DataTypes, setFile2DataTypes] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false); 
  
  const navigate = useNavigate();


  const fetchTitles = async () => {
    const apiName = 'ConfigTitles';
    const path = '/configtitles';
    try {
      const user = await Auth.currentAuthenticatedUser();
      const organization = user.attributes['custom:organization'];
      
      
      
      
      const requestBody = {
        organization: organization
      };
  
      const response = await API.post(apiName, path, { body: requestBody });
      setExistingTitles(response);
    } catch (err) {
      
    }
  };

  const addColumn = () => {
    setForm(prevState => ({
      ...prevState,
      columns: [...prevState.columns, { standardName: '', nameInFile1: '', nameInFile2: '', dataType: '' }],
    }));
  };
  

  const isHeaderSelected = (header, columnName) => {
    return form.columns.some(column => column[columnName] === header);
  };
  
  const headerSelectionCount = (header, columnName) => {
    return form.columns.reduce((count, column) => count + (column[columnName] === header ? 1 : 0), 0);
  };
  


  const handleRemoveClick = (index, event) => {
    event.preventDefault();
    const list = [...form.columns];
    list.splice(index, 1);
    setForm(prevState => ({ ...prevState, columns: list }));
  };

  const handleTypeChange = (e) => {
    const type = e.target.value;
    setForm(prevState => ({
      ...prevState,
      type: type,
      
    }));
  };





  const sanitizeInput = (value) => {
    // Use DOMPurify for sanitization
    return DOMPurify.sanitize(value);
  };
  
  const validateLength = (value, maxLength = 50) => {
    return value.length <= maxLength;
  };
  
  // Modify existing handlers to include sanitization and length validation
  const handleTitleChange = (e) => {
    const value = sanitizeInput(e.target.value.replace(/\s/g, ""));
    if (validateLength(value)) {
      setIsTitleDuplicate(existingTitles.includes(value));
      setForm(prevState => ({ ...prevState, title: value }));
    }
  };
  
  const handleFileName1Change = (e) => {
    const value = sanitizeInput(e.target.value.replace(/\s/g, ""));
    if (validateLength(value)) {
      setForm(prevState => ({ ...prevState, filename1: value }));
    }
  };
  
  const handleFileName2Change = (e) => {
    const value = sanitizeInput(e.target.value.replace(/\s/g, ""));
    if (validateLength(value)) {
      setForm(prevState => ({ ...prevState, filename2: value }));
    }
  };
  
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const sanitizedValue = sanitizeInput(value);
    if (validateLength(sanitizedValue)) {
      const list = [...form.columns];
      list[index][name] = sanitizedValue;
      if (name === 'nameInFile1') {
        list[index]['standardName'] = sanitizedValue;
      }
      setForm(prevState => ({ ...prevState, columns: list }));
    }
  };
  




  
  const validFileTypes = [
    'text/csv', 
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // official MIME type for .xlsx
    'application/vnd.ms-excel', // added for older .xls files, sometimes incorrectly assigned to .xlsx files
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // added again for .xlsx files, in case of inconsistency
    'application/octet-stream' // generic binary stream, sometimes used by browsers/systems for .xlsx files
  ];
  
  const maxFileSize = 500000 * 1024 * 1024; // 5MB
  
  const isValidFileType = (file) => validFileTypes.includes(file.type);
  const isValidFileSize = (file) => file.size <= maxFileSize;
  
  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    if (!file) {
        
        return;
    }

    if (!isValidFileType(file)) {
        // Optionally, you can show a specific error message to the user here
        return;
    }

    if (!isValidFileSize(file)) {
        // Optionally, you can show a specific error message to the user here
        return;
    }

    // If the code execution reaches here, the file is valid
    setFile1(file);
    setForm(prevState => ({ ...prevState, filename1: file.name }));
};

  
  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file && isValidFileType(file) && isValidFileSize(file)) {
      setFile2(file);
      setForm(prevState => ({ ...prevState, filename2: file.name }));
    } else {
      // Handle invalid file case (e.g., show an error message)
      
    }
  };

  const sendFiles = async () => {
    try {
      if (file1 && file2) {
        setIsProcessing(true); // Start processing
  
        await Storage.put(file1.name, file1);
        await Storage.put(file2.name, file2);
  
        // Trigger your lambda function here
        const apiName = 'fileuploadapi';
        const path = '/filematch';
        const init = {
          body: {
            file1Name: file1.name,
            file2Name: file2.name
          },
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        };
  
        const response = await API.post(apiName, path, init);
        
  
        // Update form state based on the response
        const newColumns = response.matchingColumns.map(column => ({
          standardName: column.standardName,
          nameInFile1: column.nameInFile1,
          nameInFile2: column.nameInFile2,
          dataType: response.file1DataTypes[column.nameInFile1] || response.file2DataTypes[column.nameInFile2] || ''
        }));
  
        // Update form state with the new columns and data types
        setForm(prevState => ({
          ...prevState,
          columns: newColumns
        }));
  
        setFile1Headers(response.file1Headers);
        setFile2Headers(response.file2Headers);
        setFile1DataTypes(response.file1DataTypes);
        setFile2DataTypes(response.file2DataTypes);
      } else {
        
      }
    } catch (err) {
      
    } finally {
      setIsProcessing(false); // End processing
    }
  };
  

  const hasDuplicateSelections = () => {
    const file1Selections = form.columns.map(column => column.nameInFile1);
    const file2Selections = form.columns.map(column => column.nameInFile2);
  
    const hasDuplicates = (arr) => new Set(arr).size !== arr.length;
  
    return hasDuplicates(file1Selections) || hasDuplicates(file2Selections);
  };
  const areAllDataTypesPopulated = () => {
    return form.columns.every(column => column.dataType !== '');
  };
  
  



  useEffect(() => {
    fetchTitles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check user's plan and number of existing configurations
    const user = await Auth.currentAuthenticatedUser();
    const plan = user.attributes['custom:plan'];
    

  
    if (!form.title || !form.type || !form.filename1 || !form.filename2 || form.columns.length === 0) {
      alert('Please fill all the fields');
      return;
    }
  
    if (hasDuplicateSelections()) {
      alert('Each header can only match to one header on the other side. Please resolve duplicate selections.');
      return;
    }
  
    if (!areAllDataTypesPopulated()) {
      alert('Please ensure all Data Types are populated.');
      return;
    }

    if (plan !== 'pro' && existingTitles.length >= 2) {
      alert("You're at your Limit: Upgrade to Add More Configurations");
      return;
    }
  
    setLoading(true);
  
    try {
      const organization = user.attributes['custom:organization'];
      const email = user.attributes['email'];
  
      const apiName = 'configapi';
      const path = '/config';
      const init = {
        body: { ...form, organization, email },
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      };
  
      const response = await API.post(apiName, path, init);
    
      setLoading(false);
      setFormSubmitted(true); // Set formSubmitted to true after successful submission
    } catch (err) {
     
      setLoading(false);
    }
  };
  

  const navigateToReconciliationPage = () => {
    navigate(`/reconciliation/${form.title}`);
  };
  
  

  return (
    <div className="config-form-container">
      <Link to="/" className="back-to-home-btn">Back to Home</Link>
      <h2>Create Configuration</h2>
      <hr className="divider-line" /> 
  
      <form className="config-form">
        <div className="input-group">
          <label>Title</label>
          <input type="text" value={form.title} onChange={handleTitleChange} />
          {isTitleDuplicate && <span className="error">This title already exists. Please choose a different one.</span>}
        </div>
  
        <div className="input-group">
          <label>Reconciliation Type</label>
          <select value={form.type} onChange={handleTypeChange}>
            <option value="">Select Type</option>
            <option value="trade">Trade</option>
            <option value="position">Position</option>
            <option value="cash">Cash</option>
            <option value="bank">Bank</option>
            <option value="fee">Fee</option>
            <option value="other">Other</option>
          </select>
        </div>

        <hr className="divider-line" /> 
        
  
        
  
        <div className="file-processing-section">
          
  <div className="input-group">
    <label>Side A File: {form.filename1 ? form.filename1 : "No file selected"}</label>
    <input type="file" onChange={handleFileChange1} />
  </div>

  <div className="input-group">
    <label>Side B File: {form.filename2 ? form.filename2 : "No file selected"}</label>
    <input type="file" onChange={handleFileChange2} />
  </div>

  <button type="button" onClick={sendFiles} disabled={isProcessing}>
    {isProcessing ? 'Mapping...' : 'Map File Headers'}
  </button>
</div>

<hr className="divider-line" /> 
 
  
{form.columns.map((x, i) => {
  return (
    <div className="box" key={i}>
      <div className="input-row">
        <div className="input-group">
          <label>Standard Name</label>
          <select
            className="input-field"
            name="standardName"
            value={x.standardName}
            onChange={e => handleInputChange(e, i)}
          >
            <option value="">Select Standard Name</option>
            {x.nameInFile1 && <option value={x.nameInFile1}>{x.nameInFile1}</option>}
            {x.nameInFile2 && <option value={x.nameInFile2}>{x.nameInFile2}</option>}
          </select>
        </div>
        <div className="input-group">
          <label>Name in File 1</label>
          <select name="nameInFile1" value={x.nameInFile1} onChange={e => handleInputChange(e, i)}>
            <option value="">Select a column</option>
            {file1Headers.map(header => {
              const selectionCount = headerSelectionCount(header, 'nameInFile1');
              return (
                <option key={header} value={header}>
                  {header} {'✓'.repeat(selectionCount)}
                </option>
              );
            })}
          </select>
        </div>
        <div className="input-group">
          <label>Name in File 2</label>
          <select name="nameInFile2" value={x.nameInFile2} onChange={e => handleInputChange(e, i)}>
            <option value="">Select a column</option>
            {file2Headers.map(header => {
              const selectionCount = headerSelectionCount(header, 'nameInFile2');
              return (
                <option key={header} value={header}>
                  {header} {'✓'.repeat(selectionCount)}
                </option>
              );
            })}
          </select>
        </div>
        <div className="input-group">
          <label>Data Type</label>
          <select name="dataType" value={x.dataType} onChange={e => handleInputChange(e, i)}>
            <option value="">Select Data Type</option>
            <option value="text">Text</option>
            <option value="number">Number</option>
            <option value="date">Date</option>
          </select>
        </div>
      </div>
      <div className="btn-box">
        {form.columns.length !== 1 && 
          <button type="button" className="mr10" onClick={(event) => handleRemoveClick(i, event)}>
            Remove
          </button>}
        {form.columns.length - 1 === i && 
          <button type="button" onClick={addColumn} className="add-btn">
            Add
          </button>}
      </div>
    </div>
  );
})}

  
        <div className="config-form-button-group">
          <button className="submit-btn" type="button" onClick={handleSubmit} disabled={loading || isTitleDuplicate}>
            {loading ? "Loading..." : "Submit"}
          </button>
  
          {formSubmitted && (
            <button className="submit-btn" onClick={navigateToReconciliationPage}>
              Open Recon
            </button>
          )}
        </div>
      </form>
    </div>
  );
  


};



export default ConfigForm;

