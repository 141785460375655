import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { MdDelete } from 'react-icons/md';
import { IoDuplicate } from 'react-icons/io5';
import './SftpTab.css';
import { Auth } from 'aws-amplify';

function SFTPTab({ configData, title }) {
    const [sftpConfigA, setSftpConfigA] = useState({});
    const [sftpConfigB, setSftpConfigB] = useState({});
    const [droppedFilesA, setDroppedFilesA] = useState([]);
    const [droppedFilesB, setDroppedFilesB] = useState([]);
    const [regexA, setRegexA] = useState('');
    const [regexB, setRegexB] = useState('');

    useEffect(() => {
        const fetchSftpConfig = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const organization = user.attributes['custom:organization'];

                const apiName = 'GetSftpMap';
                const path = '/getSftpMap';
                const bodyData = {
                    title,
                    organization,
                };

                const response = await API.post(apiName, path, { body: bodyData });
                

                // Update state with fetched data
                if (response.SideA) {
                    setRegexA(response.SideA.regex);
                    setDroppedFilesA(response.SideA.filenames.map(name => ({ name })));
                }
                if (response.SideB) {
                    setRegexB(response.SideB.regex);
                    setDroppedFilesB(response.SideB.filenames.map(name => ({ name })));
                }
            } catch (error) {
                
            }
        };

        fetchSftpConfig();
    }, [title]);

    const validFileTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const maxFileSize = 5 * 1024 * 1024; // 5MB

    const isValidFileType = (file) => validFileTypes.includes(file.type);
    const isValidFileSize = (file) => file.size <= maxFileSize;

    const handleDragOver = (event) => {
        event.preventDefault(); // This prevents the browser's default drag-and-drop action
    };

    const handleSftpDropA = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setDroppedFilesA(prevFiles => [...prevFiles, ...files.filter(file => isValidFileType(file) && isValidFileSize(file))]);
    };

    const handleSftpDropB = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setDroppedFilesB(prevFiles => [...prevFiles, ...files.filter(file => isValidFileType(file) && isValidFileSize(file))]);
    };

    const processDroppedFiles = async (files, side) => {
        const filenames = files.map(file => file.name);
        try {
            const apiName = 'GenerateRegex';
            const path = '/generateRegex';
            const bodyData = {
                filenames,
                side,
                title,
            };

            const response = await API.post(apiName, path, { body: bodyData });
            

            // Update regex state based on response
            if (side === 'SideA') {
                setRegexA(response.regex);
            } else if (side === 'SideB') {
                setRegexB(response.regex);
            }

            // Optionally handle post-process logic, e.g., updating state or notifying user
        } catch (error) {
            
        }
    };

    const saveSftpConfig = async (config, side, regex, filenames) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const organization = user.attributes['custom:organization'];

            const apiName = 'SaveSftpMap';
            const path = '/saveSftpMap';
            const bodyData = {
                title,
                side,
                organization,
                regex,
                filenames,
            };


            const response = await API.post(apiName, path, { body: bodyData });
           

            // Optionally handle post-save logic, e.g., updating state or notifying user
        } catch (error) {
            
        }
    };

    const handleSaveSftpA = () => saveSftpConfig(sftpConfigA, 'SideA', regexA, droppedFilesA.map(file => file.name));
    const handleSaveSftpB = () => saveSftpConfig(sftpConfigB, 'SideB', regexB, droppedFilesB.map(file => file.name));

    const handleRemoveFileA = (index) => {
        const updatedFiles = [...droppedFilesA];
        updatedFiles.splice(index, 1);
        setDroppedFilesA(updatedFiles);
    };

    const handleRemoveFileB = (index) => {
        const updatedFiles = [...droppedFilesB];
        updatedFiles.splice(index, 1);
        setDroppedFilesB(updatedFiles);
    };

    const handleDuplicateFileA = (index) => {
        const fileToDuplicate = droppedFilesA[index];
        setDroppedFilesA(prevFiles => [...prevFiles, fileToDuplicate]);
    };

    const handleDuplicateFileB = (index) => {
        const fileToDuplicate = droppedFilesB[index];
        setDroppedFilesB(prevFiles => [...prevFiles, fileToDuplicate]);
    };

    const handleFileNameChangeA = (index, newName) => {
        const updatedFiles = [...droppedFilesA];
        updatedFiles[index] = { ...updatedFiles[index], name: newName };
        setDroppedFilesA(updatedFiles);
    };

    const handleFileNameChangeB = (index, newName) => {
        const updatedFiles = [...droppedFilesB];
        updatedFiles[index] = { ...updatedFiles[index], name: newName };
        setDroppedFilesB(updatedFiles);
    };

    const hasDuplicateFilenames = (files) => {
        const names = files.map(file => file.name);
        return new Set(names).size !== names.length;
    };

    return (
        <div className="sftp-tab-container">
            <div className="file-selection">
                <div className="side-selection">
                    <div className="sideA">
                        <div className="title-and-save">
                            <h3>Side A</h3>
                            <button className="save-btn" onClick={handleSaveSftpA}>
                                Save
                            </button>
                        </div>
                        <div className="instructions">
                            <p>Drop two or more files that belong to this side from different days to complete SFTP setup.</p>
                            <p><strong>Regex Formula:</strong></p>
                            <input
                                type="text"
                                className="regex-input"
                                value={regexA}
                                onChange={(e) => setRegexA(e.target.value)}
                            />
                        </div>
                        <div className="drag-drop-section" onDragOver={handleDragOver} onDrop={handleSftpDropA}>
                            Drag and Drop files here or <span className="file-select-link" onClick={() => document.getElementById('sftp-file-input-a').click()}>select them</span>
                        </div>
                        <input
                            type="file"
                            id="sftp-file-input-a"
                            className="file-input"
                            onChange={(e) => {
                                const files = Array.from(e.target.files);
                                setDroppedFilesA(prevFiles => [...prevFiles, ...files.filter(file => isValidFileType(file) && isValidFileSize(file))]);
                            }}
                            multiple // Allow multiple file selection
                        />
                        <button className="sftp-process-btn" onClick={() => processDroppedFiles(droppedFilesA, 'SideA')} disabled={droppedFilesA.length < 2 || hasDuplicateFilenames(droppedFilesA)}>
                            Process Files
                        </button>
                        {droppedFilesA.length > 0 && (
                            <ul className="files-list">
                                {droppedFilesA.map((file, index) => (
                                    <li key={index} className="file-item">
                                        <input
                                            type="text"
                                            className="filename-input"
                                            value={file.name}
                                            onChange={(e) => handleFileNameChangeA(index, e.target.value)}
                                        />
                                        <button className="delete-btn" onClick={() => handleRemoveFileA(index)}>
                                            <MdDelete /> 
                                        </button>
                                        <button className="duplicate-btn" onClick={() => handleDuplicateFileA(index)}>
                                            <IoDuplicate /> 
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                <div className="side-selection">
                    <div className="sideB">
                        <div className="title-and-save">
                            <h3>Side B</h3>
                            <button className="save-btn" onClick={handleSaveSftpB}>
                                Save
                            </button>
                        </div>
                        <div className="instructions">
                            <p>Drop two or more files that belong to this side from different days to complete SFTP setup.</p>
                            <p><strong>Regex Formula:</strong></p>
                            <input
                                type="text"
                                className="regex-input"
                                value={regexB}
                                onChange={(e) => setRegexB(e.target.value)}
                            />
                        </div>
                        <div className="drag-drop-section" onDragOver={handleDragOver} onDrop={handleSftpDropB}>
                            Drag and Drop files here or <span className="file-select-link" onClick={() => document.getElementById('sftp-file-input-b').click()}>select them</span>
                        </div>
                        <input
                            type="file"
                            id="sftp-file-input-b"
                            className="file-input"
                            onChange={(e) => {
                                const files = Array.from(e.target.files);
                                setDroppedFilesB(prevFiles => [...prevFiles, ...files.filter(file => isValidFileType(file) && isValidFileSize(file))]);
                            }}
                            multiple // Allow multiple file selection
                        />
                        <button className="sftp-process-btn" onClick={() => processDroppedFiles(droppedFilesB, 'SideB')} disabled={droppedFilesB.length < 2 || hasDuplicateFilenames(droppedFilesB)}>
                            Process Files
                        </button>
                        {droppedFilesB.length > 0 && (
                            <ul className="files-list">
                                {droppedFilesB.map((file, index) => (
                                    <li key={index} className="file-item">
                                        <input
                                            type="text"
                                            className="filename-input"
                                            value={file.name}
                                            onChange={(e) => handleFileNameChangeB(index, e.target.value)}
                                        />
                                        <button className="delete-btn" onClick={() => handleRemoveFileB(index)}>
                                            <MdDelete /> 
                                        </button>
                                        <button className="duplicate-btn" onClick={() => handleDuplicateFileB(index)}>
                                            <IoDuplicate /> 
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SFTPTab;
