import React from 'react';

// Function to handle changes in number transformations and thresholds
export const handleNumberTransformationChange = (fieldName, type, value, setFieldsConfig, fileSide = null) => {
    setFieldsConfig(prevFieldsConfig => {
        const updatedFieldsConfig = { ...prevFieldsConfig };
        const numberTransformations = updatedFieldsConfig[fieldName]?.numberTransformations || {
            file1: { scalingFactor: '', offsetValue: '' },
            file2: { scalingFactor: '', offsetValue: '' },
            rounding: '',
            thresholds: { amount: '', percent: '' }
        };

        if (type === 'amount' || type === 'percent') {
            numberTransformations.thresholds[type] = value;
        } else if (type === 'rounding') {
            numberTransformations.rounding = value;
        } else {
            numberTransformations[fileSide][type] = value;
        }

        updatedFieldsConfig[fieldName] = { ...updatedFieldsConfig[fieldName], numberTransformations };
        return updatedFieldsConfig;
    });
};

// Component to render the number thresholds input fields
export const NumberThresholdsSection = ({ fieldName, fieldsConfig, handleNumberTransformationChange, setFieldsConfig }) => (
    <div className="number-thresholds-section">
        <div className="number-threshold-item">
            <label>Amount Threshold:</label>
            <input
                type="text"
                value={fieldsConfig[fieldName]?.numberTransformations?.thresholds?.amount || ''}
                placeholder="Enter amount threshold"
                onChange={(e) => handleNumberTransformationChange(fieldName, 'amount', e.target.value, setFieldsConfig)}
            />
        </div>
        <div className="number-threshold-item">
            <label>Percent Threshold:</label>
            <input
                type="text"
                value={fieldsConfig[fieldName]?.numberTransformations?.thresholds?.percent || ''}
                placeholder="Enter percent threshold"
                onChange={(e) => handleNumberTransformationChange(fieldName, 'percent', e.target.value, setFieldsConfig)}
            />
        </div>
    </div>
);

// Component to render the scaling factor input fields
export const ScalingSection = ({ fieldName, fieldsConfig, handleNumberTransformationChange, setFieldsConfig }) => (
    <div className="number-transformation-section">
        <div>
            <label>Scaling Factor for File 1:</label>
            <input
                type="number"
                value={fieldsConfig[fieldName]?.numberTransformations?.file1?.scalingFactor || ''}
                onChange={(e) => handleNumberTransformationChange(fieldName, 'scalingFactor', e.target.value, setFieldsConfig, 'file1')}
            />
        </div>
        <div>
            <label>Scaling Factor for File 2:</label>
            <input
                type="number"
                value={fieldsConfig[fieldName]?.numberTransformations?.file2?.scalingFactor || ''}
                onChange={(e) => handleNumberTransformationChange(fieldName, 'scalingFactor', e.target.value, setFieldsConfig, 'file2')}
            />
        </div>
    </div>
);

// Component to render the offset adjustment input fields
export const OffsetAdjustmentSection = ({ fieldName, fieldsConfig, handleNumberTransformationChange, setFieldsConfig }) => (
    <div className="number-transformation-section">
        <div>
            <label>Offset Value for File 1:</label>
            <input
                type="number"
                value={fieldsConfig[fieldName]?.numberTransformations?.file1?.offsetValue || ''}
                onChange={(e) => handleNumberTransformationChange(fieldName, 'offsetValue', e.target.value, setFieldsConfig, 'file1')}
            />
        </div>
        <div>
            <label>Offset Value for File 2:</label>
            <input
                type="number"
                value={fieldsConfig[fieldName]?.numberTransformations?.file2?.offsetValue || ''}
                onChange={(e) => handleNumberTransformationChange(fieldName, 'offsetValue', e.target.value, setFieldsConfig, 'file2')}
            />
        </div>
    </div>
);

// Component to render the rounding input fields
export const RoundingSection = ({ fieldName, fieldsConfig, handleNumberTransformationChange, setFieldsConfig }) => {
    const roundingOptions = [
        { value: 'nearest_100', label: 'Nearest 100' },
        { value: 'nearest_10', label: 'Nearest 10' },
        { value: 'nearest_1', label: 'Nearest 1' },
        { value: '1_decimal', label: '1 Decimal Place' },
        { value: '2_decimals', label: '2 Decimal Places' },
        { value: '3_decimals', label: '3 Decimal Places' },
        { value: '4_decimals', label: '4 Decimal Places' }
    ];

    return (
        <div className="rounding-section">
            <div className="rounding-item">
                <label>Rounding:</label>
                <select
                    value={fieldsConfig[fieldName]?.numberTransformations?.rounding || ''}
                    onChange={(e) => handleNumberTransformationChange(fieldName, 'rounding', e.target.value, setFieldsConfig)}
                >
                    <option value="">Select Rounding</option>
                    {roundingOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};
