import React, { useState, useEffect } from 'react';
import { Auth, API } from 'aws-amplify';
import {
  handleMappingChange,
  addNewRow,
  deleteRow,
  MappingsSection,
  handleTextTransformationChange,
  UppercaseLowercaseSection,
  SubstringExtractionSection,
  handleIgnoreCharacterChange,
  addIgnoreCharacter,
  removeIgnoreCharacter,
  IgnoreCharactersSection
} from './textTransformations';
import {
  handleNumberTransformationChange,
  ScalingSection,
  OffsetAdjustmentSection,
  NumberThresholdsSection,
  RoundingSection
} from './numberTransformations';
import {
  handleDateTransformationChange,
  FormatConversionSection,
  DateArithmeticSection,
  ExtractDateComponentSection,
  DateThresholdsSection
} from './dateTransformations';
import { AggregationMethodSection, handleAggregationChange } from './AggregationSection';
//import './TransformationsTab.css';

function TransformationsTab({ configData}) {


    const [fieldsConfig, setFieldsConfig] = useState({});
    const [showSaveSuccess, setShowSaveSuccess] = useState(false);
    const [selectedField, setSelectedField] = useState(null);


    const handleFieldSelect = (e) => {
        setSelectedField(e.target.value);
    };
    
    
    const toggleSectionVisibility = (fieldName, section) => {
        setFieldsConfig(prevFieldsConfig => ({
            ...prevFieldsConfig,
            [fieldName]: {
                ...prevFieldsConfig[fieldName],
                [section]: !prevFieldsConfig[fieldName][section],
            },
        }));
    };



    useEffect(() => {
       
        if (configData) {
            
            const initialFieldsConfig = configData.columns.reduce((acc, column) => {
                acc[column.standardName] = {
                    mappings: configData.mappings[column.standardName] || [],
                    ignoreCharacters: configData.ignoreCharacters[column.standardName] || [],
                    textTransformations: configData.textTransformations?.[column.standardName] || {
                        caseConversion: '',
                        substringStart: '',
                        substringEnd: '',
                        beforeChar: '',
                        afterChar: ''
                    },
                    numberTransformations: configData.numberTransformations?.[column.standardName] || {
                        file1: { scalingFactor: '', offsetValue: '' },
                        file2: { scalingFactor: '', offsetValue: '' },
                        rounding: configData.rounding?.[column.standardName] || '',
                       
                    },
                    dateTransformations: configData.dateTransformations?.[column.standardName] || {
                        format: '',
                        arithmetic: { value: '', unit: '' },
                        extract: '',
                        threshold: configData.dateThresholds?.[column.standardName]?.days || ''
                    },
                    aggregation: configData.aggregation?.[column.standardName] || {
                        method: '',
                        aggregateBy: []
                    },
                    
                    dataType: column.dataType || 'Unknown',
                    showMappings: false,
                    showIgnoreCharacters: false,
                    showNumberThresholds: false,
                    showDateThresholds: false,
                    showRounding: false,
                    showCaseConversion: false,
                    showSubstringExtraction: false,
                    showScaling: false,
                    showOffsetAdjustment: false,
                    showFormatConversion: false,
                    showDateArithmetic: false,
                    showExtractDateComponent: false,
                    showAggregation: false,
                };
                return acc;
            }, {});
            
            setFieldsConfig(initialFieldsConfig);
        }
    }, [configData]);

    const saveMappings = async (category) => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const title = configData.title;
            const userOrganization = currentUser.attributes['custom:organization'];
            const apiName = 'EditMap';
            const path = '/editmap';
            const currentMappings = fieldsConfig[category]?.mappings || [];
            const currentIgnoreCharacters = fieldsConfig[category]?.ignoreCharacters || [];
            const currentTextTransformations = fieldsConfig[category]?.textTransformations || {
                caseConversion: '',
                substringStart: '',
                substringEnd: '',
                beforeChar: '',
                afterChar: ''
            };
            const currentNumberTransformations = fieldsConfig[category]?.numberTransformations || {
                file1: { scalingFactor: '', offsetValue: '' },
                file2: { scalingFactor: '', offsetValue: '' },
                rounding: ''
            };
            const currentDateTransformations = fieldsConfig[category]?.dateTransformations || {
                format: '',
                arithmetic: { value: '', unit: '' },
                extract: '',
                threshold: ''
            };
            const currentAggregation = fieldsConfig[category]?.aggregation || {
                method: '',
                aggregateBy: []
            };

            const bodyData = {
                title: title,
                category: category,
                mappings: currentMappings,
                ignoreCharacters: currentIgnoreCharacters,
                textTransformations: currentTextTransformations,
                numberTransformations: currentNumberTransformations,
                dateTransformations: currentDateTransformations,
                aggregation: currentAggregation,
                organization: userOrganization,
            };

            

            const response = await API.post(apiName, path, { body: bodyData });
       

        if (response.success) {
            alert('Configuration updated successfully');
        } else {
            alert('Error saving changes, please try again.');
        }

    } catch (error) {
        
        alert('Error saving changes, please try again.');
    }
};




    return (
        <div className="config-tab-container">
            {configData && (
                <div className="config-data">
                    <h2 className="transform-data-title">Select a Field to Transform</h2>
           

                  



<div className="config-accordion">
<div className="accordion-title">

     
        <div className="custom-dropdown">
            <select id="field-select" onChange={handleFieldSelect} value={selectedField || ''}>
                <option value="" >Select a Field</option>
                {configData.columns.map((column, index) => (
                    <option key={index} value={column.standardName}>
                        {column.standardName}
                    </option>
                ))}
            </select>
        </div>
    </div>
    {selectedField && (
        <div className="accordion-content">
            <div className="config-column-item">
                <h4 className="column-item-title">
                    {selectedField}
                    <span className="column-item-data-type">
                        ({configData.columns.find(col => col.standardName === selectedField).dataType || 'Unknown'})
                    </span>
                </h4>
                {/* Conditionally render buttons based on data type */}
                {configData.columns.find(col => col.standardName === selectedField).dataType === 'text' && (
                    <>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showMappings')}>
                            Mappings{fieldsConfig[selectedField]?.mappings?.length > 0 ? ' ✓' : ''}
                        </button>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showIgnoreCharacters')}>
                            Ignore Characters{fieldsConfig[selectedField]?.ignoreCharacters?.length > 0 ? ' ✓' : ''}
                        </button>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showCaseConversion')}>
                            Case Conversion{fieldsConfig[selectedField]?.textTransformations?.caseConversion ? ' ✓' : ''}
                        </button>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showSubstringExtraction')}>
                            Substring Extraction
                            {fieldsConfig[selectedField]?.textTransformations?.substringStart ||
                            fieldsConfig[selectedField]?.textTransformations?.substringEnd ||
                            fieldsConfig[selectedField]?.textTransformations?.beforeChar ||
                            fieldsConfig[selectedField]?.textTransformations?.afterChar ? ' ✓' : ''}
                        </button>
                    </>
                )}
                {configData.columns.find(col => col.standardName === selectedField).dataType === 'number' && (
                    <>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showNumberThresholds')}>
                            Number Threshold
                            {fieldsConfig[selectedField]?.numberTransformations?.thresholds?.amount ||
                            fieldsConfig[selectedField]?.numberTransformations?.thresholds?.percent ? ' ✓' : ''}
                        </button>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showRounding')}>
                            Round{fieldsConfig[selectedField]?.numberTransformations?.rounding ? ' ✓' : ''}
                        </button>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showScaling')}>
                            Scaling
                            {fieldsConfig[selectedField]?.numberTransformations?.file1?.scalingFactor ||
                            fieldsConfig[selectedField]?.numberTransformations?.file2?.scalingFactor ? ' ✓' : ''}
                        </button>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showOffsetAdjustment')}>
                            Offset Adjustment
                            {fieldsConfig[selectedField]?.numberTransformations?.file1?.offsetValue ||
                            fieldsConfig[selectedField]?.numberTransformations?.file2?.offsetValue ? ' ✓' : ''}
                        </button>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showAggregation')}>
                            Aggregation
                            {fieldsConfig[selectedField]?.aggregation?.method ? ' ✓' : ''}
                        </button>
                    </>
                )}
                {configData.columns.find(col => col.standardName === selectedField).dataType === 'date' && (
                    <>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showDateThresholds')}>
                            Days Threshold{fieldsConfig[selectedField]?.dateTransformations?.threshold ? ' ✓' : ''}
                        </button>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showFormatConversion')}>
                            Date Format{fieldsConfig[selectedField]?.dateTransformations?.format ? ' ✓' : ''}
                        </button>
                        <button onClick={() => toggleSectionVisibility(selectedField, 'showDateArithmetic')}>
                            Offset Days
                            {fieldsConfig[selectedField]?.dateTransformations?.arithmetic?.value ||
                            fieldsConfig[selectedField]?.dateTransformations?.arithmetic?.unit ? ' ✓' : ''}
                        </button>
                    </>
                )}

                {/* Mappings section */}
                {fieldsConfig[selectedField]?.showMappings && (
                    <MappingsSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleMappingChange={(fieldName, mapIndex, type, newValue) =>
                            handleMappingChange(fieldName, mapIndex, type, newValue, setFieldsConfig)
                        }
                        addNewRow={(fieldName) =>
                            addNewRow(fieldName, setFieldsConfig)
                        }
                        deleteRow={(fieldName, mapIndex) =>
                            deleteRow(fieldName, mapIndex, setFieldsConfig)
                        }
                    />
                )}

                {/* Ignore Characters section */}
                {fieldsConfig[selectedField]?.showIgnoreCharacters && (
                    <IgnoreCharactersSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleIgnoreCharacterChange={(fieldName, charIndex, newValue) =>
                            handleIgnoreCharacterChange(fieldName, charIndex, newValue, setFieldsConfig)
                        }
                        addIgnoreCharacter={(fieldName) =>
                            addIgnoreCharacter(fieldName, setFieldsConfig)
                        }
                        removeIgnoreCharacter={(fieldName, charIndex) =>
                            removeIgnoreCharacter(fieldName, charIndex, setFieldsConfig)
                        }
                    />
                )}

                {/* Number Thresholds section */}
                {fieldsConfig[selectedField]?.showNumberThresholds && (
                    <NumberThresholdsSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleNumberTransformationChange={(fieldName, type, value) =>
                            handleNumberTransformationChange(fieldName, type, value, setFieldsConfig)
                        }
                    />
                )}

                {/* Rounding section */}
                {fieldsConfig[selectedField]?.showRounding && (
                    <RoundingSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleNumberTransformationChange={handleNumberTransformationChange}
                        setFieldsConfig={setFieldsConfig}
                    />
                )}

                {fieldsConfig[selectedField]?.showScaling && (
                    <ScalingSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleNumberTransformationChange={handleNumberTransformationChange}
                        setFieldsConfig={setFieldsConfig}
                    />
                )}

                {fieldsConfig[selectedField]?.showOffsetAdjustment && (
                    <OffsetAdjustmentSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleNumberTransformationChange={handleNumberTransformationChange}
                        setFieldsConfig={setFieldsConfig}
                    />
                )}

                {/* Aggregation section */}
                {fieldsConfig[selectedField]?.showAggregation && (
                    <AggregationMethodSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleAggregationChange={(fieldName, type, value) =>
                            handleAggregationChange(fieldName, type, value, setFieldsConfig)
                        }
                    />
                )}

                {/* Case Conversion section */}
                {fieldsConfig[selectedField]?.showCaseConversion && (
                    <UppercaseLowercaseSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleTextTransformationChange={(fieldName, type, value) =>
                            handleTextTransformationChange(fieldName, type, value, setFieldsConfig)
                        }
                    />
                )}

                {/* Substring Extraction section */}
                {fieldsConfig[selectedField]?.showSubstringExtraction && (
                    <SubstringExtractionSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleTextTransformationChange={(fieldName, type, value) =>
                            handleTextTransformationChange(fieldName, type, value, setFieldsConfig)
                        }
                    />
                )}

                {/* Date Thresholds section */}
                {fieldsConfig[selectedField]?.showDateThresholds && (
                    <DateThresholdsSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleDateTransformationChange={handleDateTransformationChange}
                        setFieldsConfig={setFieldsConfig}
                    />
                )}

                {/* Format Conversion section */}
                {fieldsConfig[selectedField]?.showFormatConversion && (
                    <FormatConversionSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleDateTransformationChange={(fieldName, type, value) =>
                            handleDateTransformationChange(fieldName, type, value, setFieldsConfig)
                        }
                    />
                )}

                {/* Date Arithmetic section */}
                {fieldsConfig[selectedField]?.showDateArithmetic && (
                    <DateArithmeticSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleDateTransformationChange={(fieldName, type, value) =>
                            handleDateTransformationChange(fieldName, type, value, setFieldsConfig)
                        }
                    />
                )}

                {/* Extract Date Component section */}
                {fieldsConfig[selectedField]?.showExtractDateComponent && (
                    <ExtractDateComponentSection
                        fieldName={selectedField}
                        fieldsConfig={fieldsConfig}
                        handleDateTransformationChange={(fieldName, type, value) =>
                            handleDateTransformationChange(fieldName, type, value, setFieldsConfig)
                        }
                    />
                )}

                {/* Conditionally render Save Configurations button */}
                {(fieldsConfig[selectedField]?.showMappings || fieldsConfig[selectedField]?.showIgnoreCharacters ||
                    fieldsConfig[selectedField]?.showNumberThresholds || fieldsConfig[selectedField]?.showDateThresholds ||
                    fieldsConfig[selectedField]?.showRounding || fieldsConfig[selectedField]?.showCaseConversion ||
                    fieldsConfig[selectedField]?.showSubstringExtraction || fieldsConfig[selectedField]?.showScaling ||
                    fieldsConfig[selectedField]?.showOffsetAdjustment || fieldsConfig[selectedField]?.showFormatConversion ||
                    fieldsConfig[selectedField]?.showDateArithmetic || fieldsConfig[selectedField]?.showExtractDateComponent ||
                    fieldsConfig[selectedField]?.showAggregation) && (
                    <button onClick={() => saveMappings(selectedField)}>Save Configurations</button>
                )}
            </div>
        </div>
    )}
</div>


                
                  
                </div>
            )}


        </div>
    );
}


export default TransformationsTab;
