import React from 'react';
import './ConfigurationTab.css';
import './EditConfigForm.css';
import DOMPurify from 'dompurify';
function EditConfigForm({ editingConfig, setEditingConfig, saveChanges, closeEditForm }) {
    
    const sanitizeAndValidateInput = (value, maxLength = 25) => {
        const sanitizedValue = DOMPurify.sanitize(value);
    
        // Validate length
        return sanitizedValue.length <= maxLength ? sanitizedValue : sanitizedValue.substring(0, maxLength);
    };

    // Helper function to handle the changes in the columns array
    const handleColumnChange = (index, field, value) => {
        const sanitizedValue = sanitizeAndValidateInput(value);
        const newColumns = [...editingConfig.columns];
        newColumns[index][field] = sanitizedValue;
        setEditingConfig({...editingConfig, columns: newColumns});
    };
    

    const handleFileNameChange = (fileName, value) => {
        const sanitizedValue = sanitizeAndValidateInput(value);
        setEditingConfig({...editingConfig, [fileName]: sanitizedValue});
    };

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            saveChanges(editingConfig); 
        }} className="edit-config-form">
        
            <h2>Edit Configuration - {editingConfig.title}</h2>
            <div className="form-group">
                <label>Reconciliation Type:</label>
                <select 
                    className="form-control"
                    value={editingConfig.type}
                    onChange={e => setEditingConfig({...editingConfig, type: e.target.value})}
                >
                 <option value="trade">Trade</option>
    <option value="position">Position</option>
    <option value="cash">Cash</option>
    <option value="bank">Bank</option>
    <option value="fee">Fee</option>
    <option value="other">Other</option>
                </select>
            </div>
            <div className="form-group">
            <label>File Name 1:</label>
            <input 
                type="text" 
                className="form-control"
                value={editingConfig.filename1}
                onChange={e => handleFileNameChange('filename1', e.target.value.replace(/\s/g, ""))}
            />
        </div>

        <div className="form-group">
            <label>File Name 2:</label>
            <input 
                type="text" 
                className="form-control"
                value={editingConfig.filename2}
                onChange={e => handleFileNameChange('filename2', e.target.value.replace(/\s/g, ""))}
            />
        </div>
        
            {editingConfig.columns.map((column, i) => (
                <div key={i} className="column-group">
                    <div className="form-group">
                        <label>Standard Name:</label>
                        <input 
                            type="text" 
                            className="form-control"
                            value={column.standardName}
                            onChange={e => handleColumnChange(i, 'standardName', e.target.value)}
                        />
                    </div>
        
                    <div className="form-group">
                        <label>Name in File 1:</label>
                        <input 
                            type="text" 
                            className="form-control"
                            value={column.nameInFile1}
                            onChange={e => handleColumnChange(i, 'nameInFile1', e.target.value)}
                        />
                    </div>
        
                    <div className="form-group">
                        <label>Name in File 2:</label>
                        <input 
                            type="text" 
                            className="form-control"
                            value={column.nameInFile2}
                            onChange={e => handleColumnChange(i, 'nameInFile2', e.target.value)}
                        />
                    </div>

                    <div className="form-group">
    <label>Data Type:</label>
    <select 
        className="form-control"
        value={column.dataType}
        onChange={e => handleColumnChange(i, 'dataType', e.target.value)} // Correct the field name to match your structure
    >
        <option value="text">Text</option>
        <option value="number">Number</option>
        <option value="date">Date</option>
    </select>
</div>
                    <div className="column-actions">
                        {editingConfig.columns.length !== 1 && 
                        <button 
                            type="button" 
                            className="remove-btn"
                            onClick={() => {
                                const newColumns = [...editingConfig.columns];
                                newColumns.splice(i, 1);
                                setEditingConfig({...editingConfig, columns: newColumns});
                            }}>
                            Remove
                        </button>}
                        
                        {editingConfig.columns.length - 1 === i && 
                        <button 
                            type="button" 
                            className="add-btn"
                            onClick={() => {
                                const newColumns = [...editingConfig.columns, { standardName: '', nameInFile1: '', nameInFile2: '', isNumeric: false }];
                                setEditingConfig({...editingConfig, columns: newColumns});
                            }}>
                            Add
                        </button>}
                    </div>
                </div>
            ))}
        
            <div className="form-actions">
                <button type="submit" className="save-edits-btn">Save Changes</button>
                <button type="button" className="cancel-btn" onClick={closeEditForm}>Cancel</button>
            </div>
        </form>
        
    );
}

export default EditConfigForm;
