import React, { useState, useEffect } from 'react';
import { Auth, API } from 'aws-amplify';
import './PreviousBreaksTab.css';

function PreviousBreaksTab({ title }) {
  const [allResultsLambdaResponses, setAllResultsLambdaResponses] = useState({});
  const [allTimestamps, setAllTimestamps] = useState({});
  const [selectedTimestamp, setSelectedTimestamp] = useState(null);
  const [showAllResults, setShowAllResults] = useState({});



  // Function to handle showing all results
  const handleShowAllResults = (title) => {
    setShowAllResults(prevState => ({ ...prevState, [title]: true }));
    fetchAllTimestampsForTitle(title);
  };

  // Function to fetch all timestamps for a title
  const fetchAllTimestampsForTitle = async (title) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const organization = user.attributes['custom:organization'];
      
      const requestBody = {
        title: title,
        organization: organization
      };
    
      const allTimestampData = await API.post('fetchtime', `/fetchtime`, {
        body: requestBody
      });
    
      const sortedTimestamps = allTimestampData.sort((a, b) => new Date(b) - new Date(a));
      
      if (!selectedTimestamp) {
        setSelectedTimestamp(sortedTimestamps[0]);
      }
      
      setAllTimestamps(prevState => ({ ...prevState, [title]: sortedTimestamps }));
    } catch (err) {
    
    }
  };

  const getExcelDownloadUrl = async (title, timestamp, organization) => {
    // Function to sanitize the title by removing non-alphanumeric characters
    console.log(title)
    console.log(timestamp)
    console.log(organization)


    const sanitizeTitle = (title) => {
      return title.replace(/[^0-9a-zA-Z]+/g, '');
    };
  
    // Function to format the timestamp to "Mon-DD-YYYY-HH:MM" in EST/EDT
    const formatTimestamp = (timestamp) => {
      const date = new Date(timestamp);
  
      // Array of month abbreviations
      const monthAbbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
      const month = monthAbbr[date.getMonth()];
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      
      // Format the time in Eastern Time (EST/EDT)
      const hours = String(date.toLocaleTimeString('en-US', { hour: '2-digit', hour12: false, timeZone: 'America/New_York' })).padStart(2, '0');
      const minutes = String(date.toLocaleTimeString('en-US', { minute: '2-digit', timeZone: 'America/New_York' })).padStart(2, '0');
  
      return `${month}-${day}-${year}-${hours}:${minutes}`;
    };
  
    // Sanitize the title
    const safeTitle = sanitizeTitle(title);
  
    // Format the timestamp in EST/EDT
    const formattedTimestamp = formatTimestamp(timestamp);
  
    // Construct the new file key
    const fileKey = `ReconReports/${safeTitle}ReconT${formattedTimestamp}.xlsx`;
  
    try {
      const response = await API.post('DownloadUrl', '/downloadurl', {
        body: {
          fileKey: fileKey,
          organization: organization
        }
      });
      return response.presignedUrl;
    } catch (error) {
     
      return null;
    }
  };
// Function to handle the download button click
const handleDownload = async () => {
    if (!selectedTimestamp) {
        // Exit if no timestamp is selected
        return;
    }

    try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userOrganization = currentUser.attributes['custom:organization'];

        if (!userOrganization) {
            // Exit if no organization is found
            return;
        }

        // Call getExcelDownloadUrl with correct arguments
        const url = await getExcelDownloadUrl(title, selectedTimestamp, userOrganization);
        if (url) {
            window.location.href = url;
        } else {
            // Handle the case where the URL is null
            console.error("Failed to generate download URL.");
        }
    } catch (error) {
        console.error("Error downloading file: ", error);
    }
};


  useEffect(() => {
    handleShowAllResults(title);
  }, [title]);
  return (
    <>
      <div className="previous-breaks-container">
        <h2>Previous Results</h2>
        {showAllResults[title] && (
          <div>
            <select 
                className="previous-breaks-select"
                value={selectedTimestamp}
                onChange={e => setSelectedTimestamp(e.target.value)}
            >
                {allTimestamps[title] && allTimestamps[title].map(timestamp => (
                    <option key={timestamp} value={timestamp}>
                        {timestamp}
                    </option>
                ))}
            </select>
            
            {selectedTimestamp && (
                <button 
                  onClick={handleDownload} 
                  className="previous-breaks-download-link"
                >
                    Download Excel for {selectedTimestamp}
                </button>
            )}
          </div>
        )}
      </div>
    </>
  );
  
}

export default PreviousBreaksTab;
