import React from 'react';

// Function to handle changes in date transformations and thresholds
export const handleDateTransformationChange = (fieldName, type, value, setFieldsConfig) => {
    setFieldsConfig(prevFieldsConfig => {
        const updatedFieldsConfig = { ...prevFieldsConfig };
        const dateTransformations = updatedFieldsConfig[fieldName]?.dateTransformations || {
            format: '',
            arithmetic: { value: '', unit: '' },
            extract: '',
            threshold: ''
        };

        if (type === 'arithmetic') {
            dateTransformations.arithmetic.value = value;
        } else if (type === 'unit') {
            dateTransformations.arithmetic.unit = value;
        } else if (type === 'threshold') {
            dateTransformations.threshold = value;
        } else {
            dateTransformations[type] = value;
        }

        updatedFieldsConfig[fieldName] = { ...updatedFieldsConfig[fieldName], dateTransformations };
        return updatedFieldsConfig;
    });
};

// Component to render the date format input fields
export const FormatConversionSection = ({ fieldName, fieldsConfig, handleDateTransformationChange }) => (
    <div className="date-transformation-section">
        <label>Date Format:</label>
        <select
            value={fieldsConfig[fieldName]?.dateTransformations?.format || ''}
            onChange={(e) => handleDateTransformationChange(fieldName, 'format', e.target.value)}
        >
            <option value="">Select Format</option>
            <option value="MM/DD/YYYY">MM/DD/YYYY</option>
            <option value="DD/MM/YYYY">DD/MM/YYYY</option>
            <option value="YYYY-MM-DD">YYYY-MM-DD</option>
        </select>
    </div>
);

// Component to render the date arithmetic input fields
export const DateArithmeticSection = ({ fieldName, fieldsConfig, handleDateTransformationChange }) => (
    <div className="date-transformation-section">
        <label>Add/Subtract:</label>
        <input
            type="number"
            value={fieldsConfig[fieldName]?.dateTransformations?.arithmetic?.value || ''}
            onChange={(e) => handleDateTransformationChange(fieldName, 'arithmetic', e.target.value)}
        />
        <label>Unit:</label>
        <select
            value={fieldsConfig[fieldName]?.dateTransformations?.arithmetic?.unit || ''}
            onChange={(e) => handleDateTransformationChange(fieldName, 'unit', e.target.value)}
        >
            <option value="">Select Unit</option>
            <option value="days">Days</option>
            <option value="months">Months</option>
            <option value="years">Years</option>
        </select>
    </div>
);

// Component to render the extract date component input fields
export const ExtractDateComponentSection = ({ fieldName, fieldsConfig, handleDateTransformationChange }) => (
    <div className="date-transformation-section">
        <label>Extract:</label>
        <select
            value={fieldsConfig[fieldName]?.dateTransformations?.extract || ''}
            onChange={(e) => handleDateTransformationChange(fieldName, 'extract', e.target.value)}
        >
            <option value="">Select Component</option>
            <option value="year">Year</option>
            <option value="month">Month</option>
            <option value="day">Day</option>
            <option value="weekday">Weekday</option>
        </select>
    </div>
);

// Component to render the date thresholds input fields
export const DateThresholdsSection = ({ fieldName, fieldsConfig, handleDateTransformationChange, setFieldsConfig }) => (
    <div className="date-thresholds-section">
        <div className="date-threshold-item">
            <label>Threshold:</label>
            <input
                type="text"
                value={fieldsConfig[fieldName]?.dateTransformations?.threshold || ''}
                placeholder="Enter threshold"
                onChange={(e) => handleDateTransformationChange(fieldName, 'threshold', e.target.value, setFieldsConfig)}
            />
        </div>
    </div>
);
