import React, { useState, useRef, useEffect  } from "react";
import * as XLSX from "xlsx"; // Importing the XLSX library
import './StitchFunctionTab.css'; // Importing the CSS file
import { API, Auth, Storage } from 'aws-amplify';


const StitchFunctionTab = ({ configData }) => {
  const [numFiles, setNumFiles] = useState(2); // Slider starts at 2 (File 1 + 1 file)
  const [side, setSide] = useState("SideA"); // Side A or Side B selection
  const [file1, setFile1] = useState(null); // Store File 1 (previously standard file)
  const [file1Headers, setFile1Headers] = useState([]); // Store headers from File 1
  const [fileUploads, setFileUploads] = useState(Array(1).fill(null)); // For file upload inputs
  const [rowMappings, setRowMappings] = useState([]); // Store mappings for rows
  const [headers, setHeaders] = useState(Array(1).fill([])); // Store headers for each uploaded file
  const fileInputRefs = useRef([]); // References to the file input fields
  const file1InputRef = useRef(null); // Reference for File 1 input
  const [matchingResults, setMatchingResults] = useState([]); // Initialize matchingResults state
  const [checkedRows, setCheckedRows] = useState([]); // Track rows that are checked for inclusion
  const [showRows, setShowRows] = useState(false); // Control whether rows are shown
  const [processingDone, setProcessingDone] = useState(false); // Flag to track if processing is done
  const [selectedHeaders, setSelectedHeaders] = useState({}); // Track selected headers
  const tempFileHeaders = useRef({ file1: [], others: [] });
  const [downloadUrl, setDownloadUrl] = useState(null); // Store download URL for stitched file


  useEffect(() => {
    // Load pre-populated data based on selected side (SideA or SideB)
    const loadStitchData = () => {
      const stitchSide = side === 'SideA' ? 'SideAStitch' : 'SideBStitch';
      const stitchData = configData[stitchSide];
  
      if (stitchData) {
        // Load stitch headers from the configuration
        const loadedHeaders = stitchData.stitchHeaders || {};
        
        // Set the number of files dynamically based on the configuration
        const numFilesInConfig = Object.keys(loadedHeaders).length;
        setNumFiles(numFilesInConfig); // Set the number of files based on the configuration
        setFileUploads(Array(numFilesInConfig - 1).fill(null)); // Set file uploads array size for all but the first file
        setHeaders(Array(numFilesInConfig - 1).fill([])); // Set headers array size for remaining files
  
        // Extract headers for File 1 and other files
        const file1Headers = loadedHeaders[0] ? Object.values(loadedHeaders[0]) : [];
        const otherFileHeaders = Object.keys(loadedHeaders).slice(1).map(key => Object.values(loadedHeaders[key]));
        
        // Set File 1 headers and other file headers
        setFile1Headers(file1Headers);
        setHeaders(otherFileHeaders);
  
        // Load row mappings
        const rowMappings = Object.keys(loadedHeaders).map(key => ({
          formats: Object.values(loadedHeaders[key])
        }));
        setRowMappings(rowMappings);
  
        // Load checked rows
        const checkedList = stitchData.Checked || {};
        const checkedRows = Object.keys(checkedList).filter(key => checkedList[key] === "true").map(Number);
        setCheckedRows(checkedRows);
  
        // Show rows and indicate that the processing is done
        setShowRows(true);
        setProcessingDone(true);
      }
    };
  
    loadStitchData();
  }, [configData, side]);
  
  
  const handleSideChange = (newSide) => {
    setSide(newSide);
    setShowRows(false); // Reset UI until data is reloaded
    setProcessingDone(false); // Reset processing state
    setDownloadUrl(null); // Clear the download URL to prevent downloading incorrect side output
  
    // Clear file drop areas by resetting the file state
    setFile1(null); // Clear File 1
    setFile1Headers([]); // Clear headers for File 1
    setFileUploads([]); // Clear all uploaded files
    setHeaders([]); // Clear headers for other files
  
    // Reset file input fields for all files
    if (file1InputRef.current) {
      file1InputRef.current.value = ''; // Reset File 1 input
    }
  
    fileInputRefs.current.forEach((inputRef) => {
      if (inputRef) {
        inputRef.value = ''; // Reset other file inputs
      }
    });
  
    // Dynamically set the number of files based on the side's configuration
    const stitchSide = newSide === 'SideA' ? 'SideAStitch' : 'SideBStitch';
    const stitchData = configData[stitchSide];
  
    if (stitchData && stitchData.numFiles) {
      setNumFiles(stitchData.numFiles); // Set the number of files based on the config
      setFileUploads(Array(stitchData.numFiles - 1).fill(null)); // Adjust file upload slots
      setHeaders(Array(stitchData.numFiles - 1).fill([])); // Adjust header slots
    } else {
      setNumFiles(2); // Default to 2 if no data is found for the selected side
      setFileUploads(Array(1).fill(null)); // Default file upload slots
      setHeaders(Array(1).fill([])); // Default header slots
    }
  };
  
  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedCheckedRows = [...checkedRows];
    if (updatedCheckedRows.includes(index)) {
      // If row is already checked, uncheck it
      setCheckedRows(updatedCheckedRows.filter((row) => row !== index));
    } else {
      // If row is not checked, check it
      setCheckedRows([...updatedCheckedRows, index]);
    }
  };

  // Handle file count change with up/down arrows, preventing manual input
  const handleFileCountChange = (step) => {
    setNumFiles((prevCount) => {
      const newCount = Math.min(Math.max(prevCount + step, 2), 9); // Min 2, Max 9
      setFileUploads(Array(newCount - 1).fill(null)); // Adjust file upload slots
      setHeaders(Array(newCount - 1).fill([])); // Adjust header slots for other files
      return newCount;
    });
  };
  

// Function to extract headers from CSV for validation (without setting them in state)
const validateHeadersFromCSV = (csvContent) => {
  const [headerRow] = csvContent.split("\n");
  return headerRow.split(",").map(header => header.trim());
};

// Function to extract headers from XLSX for validation (without setting them in state)
const validateHeadersFromXLSX = (worksheet) => {
  const headers = [];
  const range = XLSX.utils.decode_range(worksheet['!ref']);
  const firstRow = range.s.r;
  for (let col = range.s.c; col <= range.e.c; ++col) {
    const cell = worksheet[XLSX.utils.encode_cell({ r: firstRow, c: col })];
    const header = cell ? XLSX.utils.format_cell(cell).trim() : "";
    headers.push(header);
  }
  return headers;
};

// Helper function to check if all current headers are present in new file headers
const headersContainAll = (existingHeaders, newHeaders) => {
  return existingHeaders.every(header => newHeaders.includes(header));
};
const handleFileUpload = (index, file) => {
  if (!file) return;

  const fileExtension = file.name.split('.').pop().toLowerCase();
  const reader = new FileReader();

  reader.onload = (e) => {
    let parsedHeaders = [];
    if (fileExtension === "csv") {
      const text = e.target.result;
      parsedHeaders = validateHeadersFromCSV(text);
    } else if (fileExtension === "xlsx") {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      parsedHeaders = validateHeadersFromXLSX(worksheet);
    }

    if (headersContainAll(headers[index], parsedHeaders)) {
      const newFileUploads = [...fileUploads];
      newFileUploads[index] = file;
      setFileUploads(newFileUploads);
      tempFileHeaders.current.others[index] = parsedHeaders; // Store in temp
    } else {
      alert(`The uploaded file ${file.name} does not contain all required headers.`);
    }
  };

  if (fileExtension === "csv") {
    reader.readAsText(file);
  } else if (fileExtension === "xlsx") {
    reader.readAsArrayBuffer(file);
  }
};


const handleFile1Upload = (file) => {
  if (!file) return;

  const fileExtension = file.name.split('.').pop().toLowerCase();
  const reader = new FileReader();

  reader.onload = (e) => {
    let parsedHeaders = [];
    if (fileExtension === "csv") {
      const text = e.target.result;
      parsedHeaders = validateHeadersFromCSV(text);
    } else if (fileExtension === "xlsx") {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      parsedHeaders = validateHeadersFromXLSX(worksheet);
    }

    // Temporarily store the headers without setting state
    if (headersContainAll(file1Headers, parsedHeaders)) {
      setFile1(file);
      tempFileHeaders.current.file1 = parsedHeaders; // Store in temp

    } else {
      alert('The uploaded file does not contain all required headers.');
    }
  };

  if (fileExtension === "csv") {
    reader.readAsText(file);
  } else if (fileExtension === "xlsx") {
    reader.readAsArrayBuffer(file);
  }
};



  

  // Drag-and-drop functionality for file drop zones
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileUpload(index, file);  // Upload the file using the handler for other files
    }
  };
  

  const handleClickToSelect = (index) => {
    fileInputRefs.current[index].click();
  };

  const handleFile1Drop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFile1Upload(file);  // Upload the file using the File 1 handler
    }
  };

  const handleProcess = async () => {
    if (!file1 || !fileUploads.every(file => file !== null)) {
      alert("Please upload all files before processing.");
      return;
    }
  
  
    const headersToSend = {
      file1Headers: tempFileHeaders.current.file1.map(header => header || ""), // Use headers from temp storage
      otherFilesHeaders: tempFileHeaders.current.others.map(fileHeaders =>
        fileHeaders.map(header => header || "") // Ensure no null headers
      ),
    };
  
    try {
      const response = await API.post('ProcessStitch', '/processStitch', {
        body: headersToSend, // Send headers in the correct format
      });
  
      const { matchingResults } = response;
  
      // Update state with the matching results
      setMatchingResults(matchingResults);
      
      // Set the correct headers for rendering
      setFile1Headers(tempFileHeaders.current.file1); // Set File 1 headers
      setHeaders(tempFileHeaders.current.others);     // Set other file headers
  
      setShowRows(true); // Show the rows after processing
      setProcessingDone(true); // Disable plus-minus buttons after processing
  
      // Update the rowMappings with headers from other files
      const updatedRowMappings = matchingResults.map((result, index) => ({
        formats: result.map((header) => header || ""), // Set default empty if no match
      }));
      setRowMappings(updatedRowMappings); // Set the correct headers for all files
  
      // Automatically check rows if they contain values
      const initialCheckedRows = matchingResults[0].map((_, rowIdx) => {
        const rowValues = matchingResults.slice(1).map(fileHeaders => fileHeaders[rowIdx]);
        const allNull = rowValues.every(value => value === 'null' || value === ''); // Handle null and empty strings
        return allNull ? null : rowIdx; // If all null, don't check the box
      }).filter(row => row !== null); // Filter out unchecked rows
  
      setCheckedRows(initialCheckedRows); // Automatically check relevant rows
  
      // Update selectedHeaders based on the automatically populated values
      const updatedSelectedHeaders = {};
  
      // Loop through matching results to track selected headers for each file
      matchingResults.forEach((fileResults, fileIndex) => {
        fileResults.forEach((header, rowIndex) => {
          if (header) {
            updatedSelectedHeaders[fileIndex] = [...(updatedSelectedHeaders[fileIndex] || []), header];
          }
        });
      });
  
      setSelectedHeaders(updatedSelectedHeaders); // Update selectedHeaders state with auto-populated values
  

  
    } catch (error) {
   
    }
  };
  
  
    
  
  
// Function to validate that no checked rows contain "null" in FilesHeaders
const validateSubmission = () => {


  // Iterate over checked rows
  for (let rowIndex of checkedRows) {


    // Check each column starting from the second file (since file1Headers are excluded)
    for (let colIndex = 1; colIndex < rowMappings.length; colIndex++) { 
      const rowValue = rowMappings[colIndex]?.formats?.[rowIndex];


      // If the value is "null" or empty, show an error
      if (rowValue === "null" || rowValue === "") {
        alert(`Error: Header for row ${rowIndex + 1} in File ${colIndex + 1} is "null" or missing. Please fix this before submitting.`);
        return false; // Stop submission if a null or empty value is found in a checked row
      }
    }
  }


  return true; // No null values, safe to submit
};

const handleSubmit = async () => {
  // First, validate submission
  if (!validateSubmission()) {
    return; // Stop if validation fails
  }
  // Prepare the stitch headers data based on the checked rows only
  const currentUser = await Auth.currentAuthenticatedUser();
  const userOrganization = currentUser.attributes['custom:organization'];
  const stitchHeaders = {};
  const checkedList = {};
  let newIndex = 0;

  checkedRows.forEach((rowIndex) => {
    rowMappings.forEach((row, colIndex) => {
      if (!stitchHeaders[colIndex]) stitchHeaders[colIndex] = {};
      stitchHeaders[colIndex][newIndex] = row.formats[rowIndex]; // Send only the checked row data
    });
    checkedList[newIndex] = "true"; // Mark all checked rows as true
    newIndex++; // Increment the index for the next checked row
  });

  // Prepare the table data to include stitch headers, side, and checked status
  const tableData = {
    organization: userOrganization, // Replace with actual organization
    title: configData.title, // Replace with actual title
    stitchHeaders: stitchHeaders,  // Sending only the checked stitch headers with reset index
    StitchSide: side === 'SideA' ? 'SideA' : 'SideB', // Send the StitchSide
    Checked: checkedList // Send the checked/unchecked status (all "true" in this case)
  };
  
  try {
    const response = await API.post('EditMap', '/editmap', {
      body: tableData, // Sending stitch headers and checked status
    });
   
  } catch (error) {
  
  }
};


const handleClear = () => {
  setNumFiles(2); // Reset number of files to 2
  setFile1(null); // Clear File 1
  setFile1Headers([]); // Clear File 1 headers
  setFileUploads(Array(1).fill(null)); // Reset file uploads
  setRowMappings([]); // Clear row mappings
  setHeaders(Array(1).fill([])); // Clear headers for other files
  setMatchingResults([]); // Clear matching results
  setCheckedRows([]); // Clear checked rows
  setShowRows(false); // Hide the table
  setProcessingDone(false); // Enable buttons for file count

  // Reset the value of file input fields to allow the same file to be uploaded again
  if (file1InputRef.current) {
    file1InputRef.current.value = ''; // Reset File 1 input
  }

  fileInputRefs.current.forEach((inputRef) => {
    if (inputRef) {
      inputRef.value = ''; // Reset other file inputs
    }
  });
};


  const handleDropdownChange = (rowIndex, colIndex, newValue) => {
    // Update matchingResults
    const updatedResults = [...matchingResults];
    if (!updatedResults[colIndex + 1]) {
      updatedResults[colIndex + 1] = [];
    }
  
    const previousValue = updatedResults[colIndex + 1][rowIndex]; // Get the previous selected header
    updatedResults[colIndex + 1][rowIndex] = newValue; // Update the new value
    setMatchingResults(updatedResults);
  
    // Update rowMappings to keep them in sync
    const updatedRowMappings = [...rowMappings];
    if (!updatedRowMappings[colIndex + 1]) {
      updatedRowMappings[colIndex + 1] = { formats: [] };
    }
    updatedRowMappings[colIndex + 1].formats[rowIndex] = newValue;
    setRowMappings(updatedRowMappings);
  
    // Update the selectedHeaders state
    setSelectedHeaders(prevState => {
      const updatedHeaders = { ...prevState };
  
      // Ensure the file entry exists in selectedHeaders
      if (!updatedHeaders[colIndex + 1]) {
        updatedHeaders[colIndex + 1] = {};
      }
  
      // Remove the previous selection (if any)
      if (previousValue) {
        delete updatedHeaders[colIndex + 1][rowIndex]; // Remove previous header from this row
      }
  
      // Add new selection
      if (newValue) {
        updatedHeaders[colIndex + 1][rowIndex] = newValue; // Add new header to this row
      }
  
      return updatedHeaders;
    });
  };



  
  const handleStitchFiles = async () => {
    if (!file1 || !fileUploads.every(file => file !== null)) {
      alert("Please upload all files before stitching.");
      return;
    }
  
    const uploadFileToS3 = async (file) => {
      try {
        const storedFile = await Storage.put(file.name, file, {
          contentType: file.type,
        });
       
        return storedFile.key;
      } catch (error) {
       
        throw new Error("File upload failed");
      }
    };
  
    try {
     
      const file1Key = await uploadFileToS3(file1);
     
      const otherFileKeys = await Promise.all(fileUploads.map(file => uploadFileToS3(file)));
  
 
  
      // Gather headers and checked rows
      const checkedHeaders = rowMappings.map((row, idx) => {
        if (checkedRows.includes(idx)) {
          return {
            file1Header: file1Headers[idx],
            otherHeaders: headers.map(fileHeaders => fileHeaders[idx] || "")
          };
        }
        return null;
      }).filter(Boolean);
  
 
  
      // Call the API to stitch the files using Lambda
      const response = await API.post('StitchFiles', '/stitchfiles', {
        body: {
          file1Name: file1Key, // Send file name instead of URL
          otherFileNames: otherFileKeys, // Send other file names
          file1Headers: file1Headers, // Send File 1 headers
          otherFileHeaders: headers // Send headers for other files
        }
      });
  
  
      const { downloadUrl } = response;  // Access downloadUrl directly from response
      setDownloadUrl(downloadUrl); // Enable download button once URL is available
      alert('Files stitched successfully!');
    } catch (error) {
      alert('Error stitching files.');
    }
  };
  
  const handleDownload = () => {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    }
  };
  return (
    <div className="stitch-container">
      <h2 className="stitch-header">Stitch Function Configuration</h2>
  
      {/* Side A/B Selection */}
      <div className="segmented-control-container">
        <div className="segmented-control">
          <div
            className={`segment ${side === "SideA" ? "active" : ""}`}
            onClick={() => handleSideChange("SideA")}
          >
            Side A
          </div>
          <div
            className={`segment ${side === "SideB" ? "active" : ""}`}
            onClick={() => handleSideChange("SideB")}
          >
            Side B
          </div>
        </div>
      </div>
  
      {/* File count input with up/down arrows */}
      <div className="stitch-file-slider-container">
        <div className="file-count-input">
          <button
            onClick={() => handleFileCountChange(-1)}
            disabled={numFiles <= 2 || showRows}
          >
            -
          </button>
          <input
            type="text"
            value={numFiles}
            readOnly
            className="stitch-file-count-input"
          />
          <button
            onClick={() => handleFileCountChange(1)}
            disabled={numFiles >= 9 || showRows}
          >
            +
          </button>
        </div>
      </div>
  
      {/* Action Buttons */}
      <div className="stitch-actions">
        <button className="clear-stitch-button" onClick={handleClear}>
          Clear
        </button>
        <button className="submit-stitch-button" onClick={handleSubmit} disabled={!showRows}>
          Save
        </button>
      </div>
  
      {/* Display File 1 name with drop zone */}
      <table className="stitch-mapping-table">
        <thead>
          <tr>
            {/* File 1 Drop Zone */}
            <th>
              <div
                className="file-dropzone"
                onDragOver={handleDragOver}
                onDrop={handleFile1Drop}
                onClick={() => file1InputRef.current.click()}
              >
                <input
                  type="file"
                  accept=".csv, .xlsx"
                  ref={file1InputRef}
                  onChange={(e) => handleFile1Upload(e.target.files[0])}
                  className="stitch-file-input"
                />
                {file1 ? `${file1.name} (Used for Output Headers)` : "File 1"}
              </div>
            </th>
  
            {/* Dynamically generate drop zones for other files */}
            {Array.from({ length: numFiles - 1 }).map((_, idx) => (
              <th key={idx} className="file-drop-header">
                <div
                  className="file-dropzone"
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, idx)}
                  onClick={() => handleClickToSelect(idx)}
                >
                  <input
                    type="file"
                    accept=".csv, .xlsx"
                    ref={(el) => (fileInputRefs.current[idx] = el)}
                    onChange={(e) => handleFileUpload(idx, e.target.files[0])}
                    className="stitch-file-input"
                  />
                  {fileUploads[idx] ? fileUploads[idx].name : `File ${idx + 2}`}
                </div>
              </th>
            ))}
            <th>Include in Output File</th>
          </tr>
        </thead>
  
        {showRows && file1Headers.length > 0 && (
          <tbody>
            {file1Headers.map((header, rowIdx) => (
              <tr key={rowIdx}>
                <td>
                  <span>{header}</span>
                </td>
                {headers.map((fileHeaders, colIdx) => (
                  <td key={colIdx}>
                    <select
                      value={
                        matchingResults.length > 0
                          ? matchingResults[colIdx + 1]?.[rowIdx]
                          : fileHeaders[rowIdx] || ""
                      }
                      onChange={(e) => handleDropdownChange(rowIdx, colIdx, e.target.value)}
                      className="stitch-mapping-input"
                    >
                      <option value="">Select Header</option>
                      {fileHeaders.map((headerOption, headerIdx) => {
                        const selectedHeadersInFile = Object.values(
                          selectedHeaders[colIdx + 1] || {}
                        ).filter((selectedHeader, selectedRowIdx) => selectedRowIdx !== rowIdx);
                        return (
                          <option
                            key={headerIdx}
                            value={headerOption}
                            disabled={selectedHeadersInFile.includes(headerOption)}
                          >
                            {headerOption}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                ))}
                <td>
                  <input
                    type="checkbox"
                    checked={checkedRows.includes(rowIdx)}
                    onChange={() => handleCheckboxChange(rowIdx)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
  
      {/* Process, Stitch, and Download Buttons */}
      <div className="stitch-actions">
        <button className="stitch-process-button" onClick={handleProcess} disabled={showRows}>
          Process Files
        </button>
        <button
          className="stitch-files-button"
          onClick={handleStitchFiles}
          disabled={!processingDone} // Disable until Process is completed
        >
          Stitch Files
        </button>
        <button
          className="download-stitched-file-button"
          onClick={handleDownload}
          disabled={!downloadUrl}
        >
          Download Stitched File
        </button>
      </div>
    </div>
  ); 
};

export default StitchFunctionTab;





