/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "ComprehensiveMetrics",
            "endpoint": "https://0j7ndu0bug.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "ConfigData",
            "endpoint": "https://ojmfxr4l08.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "ConfigTitles",
            "endpoint": "https://bcg7ofx9gk.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "DownloadUrl",
            "endpoint": "https://uswbzgo2q5.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "EditConfig",
            "endpoint": "https://en6l6qy01a.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "EditMap",
            "endpoint": "https://gp7tipxol4.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "FetchMapping",
            "endpoint": "https://dx0fe6p5wl.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "FetchS3Files",
            "endpoint": "https://8uc6hvt5xg.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "GenerateRegex",
            "endpoint": "https://jln2ubll14.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "GetS3Url",
            "endpoint": "https://br11pzlhzh.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "GetSftpMap",
            "endpoint": "https://ppknos6s04.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "LatestResults",
            "endpoint": "https://e4ixpbtqn8.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "ProcessStitch",
            "endpoint": "https://b6bw4f7k0d.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "SaveSftpMap",
            "endpoint": "https://s9ortsxve2.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "StitchFiles",
            "endpoint": "https://5nu2k445r2.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "configapi",
            "endpoint": "https://vh5qnkzsa7.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "demo",
            "endpoint": "https://c585ohchik.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "fetchConfigAPI",
            "endpoint": "https://4bekwq8ew8.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "fetchResultsAPI",
            "endpoint": "https://kce8ubeefi.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "fetchtime",
            "endpoint": "https://vllg86ygp1.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "fileuploadapi",
            "endpoint": "https://zvydl5y9yl.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "reconcile",
            "endpoint": "https://5l2mrii4f8.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "saveBreakData",
            "endpoint": "https://ytux7v8o9f.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:220cc5e5-c4b4-431b-9ac0-157ab040f89c",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_YKFI3TQWA",
    "aws_user_pools_web_client_id": "7m9qpblgu0qq9smtqhrlnb4elt",
    "oauth": {
        "domain": "fastrecon-prod.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "reconapp-deployment-808-prod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://diaqg8ux4fp4c.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "organizationmap-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "recondata-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "reconresultsdb-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "recontimes-prod",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "reconcilefiles183944-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
