import React, { useState, useEffect } from 'react';
import { Auth, API } from 'aws-amplify';
import { Pie, Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import 'chartjs-plugin-datalabels';
import './ReconciliationMetricsDisplay.css'

Chart.register(ArcElement);



function ReconciliationMetricsDisplay({ recentResult, configData, filename1, filename2 }) {
    // State for file status
    const [fileStatus, setFileStatus] = useState({
        filename1: 'Checking...',
        filename2: 'Checking...'
    });




    const { filename1: recentFilename1, filename2: recentFilename2 } = recentResult;



    // Function to fetch all files from the API
    const fetchAllFiles = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const organization = user.attributes['custom:organization'];
            const apiName = 'FetchS3Files';
            const path = '/fetchs3files';

            const response = await API.post(apiName, path, { body: { organization } });
           
            return response.files;
        } catch (error) {
           
            throw new Error('Failed to fetch file list.');
        }
    };

    // Function to check if a specific file is present in the list
    const checkFilePresence = async (filename) => {
        try {
         
            const files = await fetchAllFiles();

            // Check if the file is in the fetched list
            const fileExists = files.some(file => file.key === filename);
      
            return fileExists;
        } catch (error) {
      
            return false;
        }
    };
    



    const getStatusCounts = () => {
        const breaks = recentResult.all_breaks.display_breaks.breaks;
        const statusCounts = {
            'Unresolved': 0,
            'In-Progress': 0,
            'Resolved': 0
        };

        breaks.forEach(breakItem => {
            if (statusCounts[breakItem.status] !== undefined) {
                statusCounts[breakItem.status]++;
            }
        });

        return statusCounts;
    };

    const statusCounts = getStatusCounts();

    useEffect(() => {
      
        if (filename1 && filename2) {
            (async () => {
                const file1Exists = await checkFilePresence(filename1);
                const file2Exists = await checkFilePresence(filename2);
    
                setFileStatus({
                    filename1: file1Exists ? 'Present' : 'Missing',
                    filename2: file2Exists ? 'Present' : 'Missing'
                });
            })();
        }
    }, [filename1, filename2]);
    

    const metrics = JSON.parse(recentResult.metrics);
    const breakCounts = JSON.parse(recentResult.break_counts);

    const formatValue = (columnName, value) => {
        // Determine if the value has decimals
        const isDecimal = value % 1 !== 0;
    
        // Format the value accordingly
        let formattedValue = isDecimal ? value.toFixed(2) : value.toString();
    
        // If the column name has "price" or "amount", prepend with `$`
        if (columnName.includes("price") || columnName.includes("amount")) {
            formattedValue = `$${formattedValue}`;
        }
        return formattedValue;
    };
    
     // Extracting the matched and unmatched percentages
     const matchedAmount = parseFloat(metrics.find(m => m.Metric === 'Matched Amount')['Set 1:']);
     const unmatchedAmount = parseFloat(metrics.find(m => m.Metric === 'Unmatched Amount')['Set 1:']);
 

     
     const pieData = {
         labels: ['Matched', 'Unmatched'],
         datasets: [{
             data: [matchedAmount, unmatchedAmount],
             backgroundColor: ['#1a9338', '#e7f138 '],  // You can adjust these colors as needed
         }]
     };

      // Pie chart options
      const pieOptions = {
        responsive: true,
        maintainAspectRatio: true,
        cutout: '75%',
        plugins: {
            datalabels: {
                display: false,
                color: 'black',
                align: 'end',
                anchor: 'end',
                formatter: function(value, context) {
                    return context.chart.data.labels[context.dataIndex] + ': ' + value + '%';
                }
            }
        }
    };
    
    

    // CSS style to adjust the chart size
    const chartStyle = {
        width: '130px',  // Width of the chart
        height: '130px'  // Height of the chart
    };

    const statusPieData = {
        
        labels: ['Unresolved', 'In-Progress', 'Resolved'],
        datasets: [{
          data: [statusCounts['Unresolved'], statusCounts['In-Progress'], statusCounts['Resolved']],
          backgroundColor: ['#f54b42', '#e7f138', '#1a9338'],  // Red, Orange, Green

        
        }]
      };
      
      const statusPieOptions = {
        responsive: true,
        maintainAspectRatio: true,
        cutout: '75%',
        plugins: {
          datalabels: {
            display: true,
            color: 'white',
            formatter: (value) => value > 0 ? value : null,
          }
        }
      };
      

    

      return (
        <div className="recon-metrics-display">


    {/* Matched vs Unmatched */}
    <div className="recon-metric-column">
        <h3 className="recon-metric-title">Matched vs Unmatched</h3>
        <div className="chart-and-stats">
          <div className="recon-metric-content chart-container" style={chartStyle}>
            <Doughnut data={pieData} options={pieOptions} />
          </div>
          <div className="chart-stats matched-chart-stats">
            <p>Matched: {matchedAmount}</p>
            <p>Unmatched: {unmatchedAmount}</p>
          </div>
        </div>
      </div>


{/* File Overview */}
        <div className="recon-metric-column">
                <h3 className="recon-metric-title">File Overview</h3>
                <div className="recon-metric-content">

                    <p><u>Current Run</u></p>
                    <p>SideA: <strong>{recentFilename1}</strong></p>
                    <p>SideB: <strong>{recentFilename2}</strong></p>
                    <p><u>Next Run</u></p>
                    <p>SideA: <strong>{filename1}</strong></p>
                    <p>SideB: <strong>{filename2}</strong></p>

                </div>
            </div>

            

{/* Break Counts */}
<div className="recon-metric-column">
    <h3 className="recon-metric-title">Break Counts</h3>
    <div className="recon-metric-content">
        {breakCounts.map((breakItem) => (
            breakItem['# of breaks'] > 0 && breakItem.column_name !== 'matched_df' && (
                <p key={breakItem.column_name}>
                    {breakItem.column_name}: {breakItem['# of breaks']}
                </p>
            )
        ))}
    </div>
</div>


{/* Value of Breaks */}
<div className="recon-metric-column">
    <h3 className="recon-metric-title">Value of Breaks</h3>
    <div className="recon-metric-content">
        {breakCounts.map((breakItem) => (
            breakItem['value of breaks'] > 0 && breakItem.column_name !== 'matched_df' && (
                <p key={breakItem.column_name}>
                    {breakItem.column_name}: {formatValue(breakItem.column_name, breakItem['value of breaks'])}
                </p>
            )
        ))}
    </div>
</div>


        

                  {/* Status */}
                  <div className="recon-metric-column">
        <h3 className="recon-metric-title">Status Distribution</h3>
        
        <div className="chart-and-stats">
        <div className="chart-stats">
            <p>Unresolved: {statusCounts['Unresolved']}</p>
            <p>In-Progress: {statusCounts['In-Progress']}</p>
            <p>Resolved: {statusCounts['Resolved']}</p>
          </div>
          <div className="recon-metric-content status-chart-container" style={chartStyle}>
  <Doughnut data={statusPieData} options={statusPieOptions} />
</div>

         
        </div>
      </div>


   



            
        </div>
    );
}

export default ReconciliationMetricsDisplay;
