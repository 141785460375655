import React, { useState, useEffect, useCallback, memo } from 'react';
import { Auth, Storage, API } from 'aws-amplify';
import './RecentResultsTable.css';
import { FaInfoCircle } from 'react-icons/fa';
import { FaExclamationCircle } from 'react-icons/fa';
import { CgMoreO } from "react-icons/cg";
import { FaDownload } from 'react-icons/fa';
import { TfiReload } from 'react-icons/tfi';
import DOMPurify from 'dompurify';
import { useNavigate } from 'react-router-dom';

// Test Icons 
import { BsCaretDownSquareFill, BsCaretUpSquareFill } from 'react-icons/bs';

function RecentResultsTable({ data, isSending, onSendToLambda, excelUrl, configData, switchToFilesTab  }) {
  const [localExcelUrl, setLocalExcelUrl] = useState(null);

  const [shownSuggestionIndex, setShownSuggestionIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBreakType, setSelectedBreakType] = useState(null);
  const [breakTypes, setBreakTypes] = useState([]);
  const [shownSuggestionRows, setShownSuggestionRows] = useState([]);
  const [shownNotesRows, setShownNotesRows] = useState([]);
  const [isInProgressCollapsed, setIsInProgressCollapsed] = useState(false);
  const [isResolvedCollapsed, setIsResolvedCollapsed] = useState(false);
  const [isUnresolvedCollapsed, setIsUnresolvedCollapsed] = useState(false);
  const [shownInfoCardIds, setShownInfoCardIds] = useState(new Set());
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [userOrganization, setUserOrganization] = useState(null);
  const [shownOriginalEntriesIds, setShownOriginalEntriesIds] = useState(new Set());

  const navigate = useNavigate();



const handleBlur = (id, field, content) => {
  const sanitizedContent = DOMPurify.sanitize(content);
  updateEditableData(id, field, sanitizedContent);
  setHasUnsavedChanges(true);
};

const saveChanges = async () => {
  try {
    const apiName = 'saveBreakData'; 
    const path = '/saveBreaks'; 

    // Retrieve the current authenticated user
    const currentUser = await Auth.currentAuthenticatedUser();
    
    // Extract the organization attribute from the user's attributes
    const userOrganization = currentUser.attributes['custom:organization']; 
    
    const bodyData = {
      title: data.title,   
      timestamp: data.timestamp, 
      breaks: editableData,
      organization: userOrganization 
    };

    const init = {
      body: bodyData,
      method: 'POST' 
    };

    const response = await API.post(apiName, path, init);
  
    setHasUnsavedChanges(false);
    
  } catch (error) {
   
  }
};

  

const toggleInProgressCollapse = () => {
  setIsInProgressCollapsed(prev => !prev);
};

const toggleResolvedCollapse = () => {
  setIsResolvedCollapsed(prev => !prev);
};

const toggleUnresolvedCollapse = () => {
  setIsUnresolvedCollapsed(prev => !prev);
};


 // New state to track the edits
 const [editableData, setEditableData] = useState(data.all_breaks.display_breaks.breaks.map((b, idx) => ({
  id: b.id || idx, // Ensure each break item has a unique ID
  ...b,
  suggestion: b.suggestion || '',
  notes: b.notes || '',
  status: b.status || 'Unresolved',
})));



// A handler to update the editable data
const updateEditableData = (id, field, value) => {
  const newData = [...editableData];
  const breakItem = newData.find(b => b.id === id);
  if (breakItem) {
    breakItem[field] = value;
  }
  setEditableData(newData);
  setHasUnsavedChanges(true); 
};


const toggleInfoCard = (id) => {
  setShownInfoCardIds((prevShownInfoCardIds) => {
    const newShownInfoCardIds = new Set(prevShownInfoCardIds);
    if (newShownInfoCardIds.has(id)) {
      newShownInfoCardIds.delete(id);
    } else {
      newShownInfoCardIds.add(id);
    }
    return newShownInfoCardIds;
  });
};



const toggleOriginalEntries = (id) => {
  setShownOriginalEntriesIds((prevShownOriginalEntriesIds) => {
    const newShownOriginalEntriesIds = new Set(prevShownOriginalEntriesIds);
    if (newShownOriginalEntriesIds.has(id)) {
      newShownOriginalEntriesIds.delete(id);
    } else {
      newShownOriginalEntriesIds.add(id);
    }
    return newShownOriginalEntriesIds;
  });
};


const handleReload = () => {
  navigate(0); // This will reload the current page
};


const getInfoIconColor = (status) => {
  switch (status) {
    case 'Unresolved':
      return '#ff0000';  // Red
    case 'In-Progress':
      return '#ffcc00';  // Yellow
    case 'Resolved':
      return '#00cc00';  // Green
    default:
      return '#000000';  // Black as default
  }
}


function hasDiscrepancy(entry1, entry2, field) {
  // Check if the field is 'file' and ignore discrepancies for it
  if (field === 'File') {
    return false;
  }

  if (entry1 && entry2) {
    return entry1[field] !== entry2[field];
  }
  return false;
}




const getExcelDownloadUrl = async (title, timestamp, organization) => {
  // Function to sanitize the title by removing non-alphanumeric characters
  const sanitizeTitle = (title) => {
    return title.replace(/[^0-9a-zA-Z]+/g, '');
  };

  // Function to format the timestamp to "Mon-DD-YYYY-HH:MM" in EST/EDT
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    // Array of month abbreviations
    const monthAbbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                       "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const month = monthAbbr[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    
    // Format the time in Eastern Time (EST/EDT)
    const hours = String(date.toLocaleTimeString('en-US', { hour: '2-digit', hour12: false, timeZone: 'America/New_York' })).padStart(2, '0');
    const minutes = String(date.toLocaleTimeString('en-US', { minute: '2-digit', timeZone: 'America/New_York' })).padStart(2, '0');

    return `${month}-${day}-${year}-${hours}:${minutes}`;
  };

  // Sanitize the title
  const safeTitle = sanitizeTitle(title);

  // Format the timestamp in EST/EDT
  const formattedTimestamp = formatTimestamp(timestamp);

  // Construct the new file key
  const fileKey = `ReconReports/${safeTitle}ReconT${formattedTimestamp}.xlsx`;

  try {
    const response = await API.post('DownloadUrl', '/downloadurl', {
      body: {
        fileKey: fileKey,
        organization: organization
      }
    });
    return response.presignedUrl;
  } catch (error) {
   
    return null;
  }
};



const handleDownload = async () => {
  if (!data || !data.timestamp) {
  
    return; // Exit the function if data or timestamp is missing
  }
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const userOrganization = currentUser.attributes['custom:organization'];
    if (!userOrganization) {
  
      return; // Exit the function if userOrganization is missing
    }
    const url = await getExcelDownloadUrl(data.title, data.timestamp, userOrganization);
    if (url) {
      window.location.href = url;
    } else {
      
    }
  } catch (error) {
    
  }
};

  useEffect(() => {
    if (data && data.all_breaks && data.all_breaks.display_breaks && data.all_breaks.display_breaks.headers) {
      const uniqueBreakTypes = Object.values(data.all_breaks.display_breaks.headers);
      setBreakTypes(uniqueBreakTypes);
    }
  }, [data]);

 
  const filterData = (breaks) => {
    if (!breaks) return [];
  

  
    const breaksMatchingType = [];
    const breaksNotMatchingType = [];
  
    breaks.forEach(breakItem => {
      const entries = breakItem.entries || [];
      
  
      if (selectedBreakType && selectedBreakType !== 'mismatches' && hasDiscrepancy(entries[0], entries[1], selectedBreakType)) {
        breaksMatchingType.push(breakItem);
      } else {
        breaksNotMatchingType.push(breakItem);
      }
    });
  
    const calculateDifference = (entry1, entry2, breakType) => {
      // Check if either entry is missing or if the breakType is undefined
      if (!entry1 || !entry2 || typeof entry1[breakType] === 'undefined' || typeof entry2[breakType] === 'undefined') {
        return 0; // Return 0 difference if data is incomplete or breakType is undefined
      }
  
      const value1 = parseFloat(entry1[breakType] || 0);
      const value2 = parseFloat(entry2[breakType] || 0);
      return Math.abs(value1 - value2);
    };
  
    const sortBreaks = (a, b) => {
      if (selectedBreakType === 'mismatches') {
        const aIsMismatch = a.label.includes('Mismatched');
        const bIsMismatch = b.label.includes('Mismatched');
        if (aIsMismatch && !bIsMismatch) return -1;
        if (!aIsMismatch && bIsMismatch) return 1;
      }
  
      // Use try-catch to handle any potential errors during difference calculation
      try {
        const diffA = calculateDifference(a.entries[0], a.entries[1], selectedBreakType);
        const diffB = calculateDifference(b.entries[0], b.entries[1], selectedBreakType);
        return diffB - diffA; // Sort by descending differences
      } catch (error) {
       
        return 0; // Default to no sorting in case of error
      }
    };
  
  
    const filteredBreaksMatchingType = breaksMatchingType
      .filter(breakItem => {
        const entries = breakItem.entries || [];
        return entries.some(entry =>
          Object.values(entry).some(val =>
            String(val).toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      })
      .sort(sortBreaks);
  
    const filteredBreaksNotMatchingType = breaksNotMatchingType
      .filter(breakItem => {
        const entries = breakItem.entries || [];
        return entries.some(entry =>
          Object.values(entry).some(val =>
            String(val).toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      })
      .sort(sortBreaks);
  
    return [...filteredBreaksMatchingType, ...filteredBreaksNotMatchingType];
  };

  const renderMetricsMessage = (metricsJson, allBreaks) => {
    const metrics = JSON.parse(metricsJson);
    const unmatchedAmountMetric = metrics.find(metric => metric.Metric === "Unmatched Amount");
    const maxUnmatchedAmount = Math.max(unmatchedAmountMetric["Set 1:"], unmatchedAmountMetric["Set 2:"]);
    const breaksAreEmpty = allBreaks && allBreaks.display_breaks && allBreaks.display_breaks.breaks.length === 0;
    if (maxUnmatchedAmount > 0 && breaksAreEmpty) {
      return `Attention Required: The reconciliation process identified a significantly high number of breaks (over ${Math.round(maxUnmatchedAmount)}), suggesting a potential configuration or data issue. Please review the detailed report by downloading the provided file. Adjustments to the configuration may be necessary to ensure accurate reconciliation. For further assistance, consult the documentation or contact support.`;
    }
    return null;
  };
  


  const renderBreakData = () => {
    if (!data || !data.all_breaks || !data.all_breaks.display_breaks || !configData || !configData.columns) {
      return null; // Return null if data or configData is not ready
    }
  
    const orderedColumnNames = configData.columns.map(column => column.standardName);
    const filteredBreaks = filterData(editableData);
  
    const headers = [...orderedColumnNames, 'File'];
  
    const groupedBreaks = filteredBreaks.reduce((grouped, breakItem) => {
      (grouped[breakItem.status] = grouped[breakItem.status] || []).push(breakItem);
      return grouped;
    }, {});
  
    return (
      <div className="break-data-container">
        <div className="action-buttons-container">
          <button 
            className="run-reconciliation-btn" 
            onClick={onSendToLambda} 
            disabled={isSending}
          >
            {isSending ? 'Processing...' : 'Run Reconciliation'}
          </button>
          <button className="file-upload-btn" onClick={switchToFilesTab}>
            Upload Files
          </button>


          <button 
            className="reload-btn" 
            onClick={handleReload}
          >
            <TfiReload size={24} />
          </button>
          <button 
            className="download-excel-btn" 
            onClick={handleDownload}
          >
            <FaDownload />
          </button>
          <button 
            className={`save-changes-btn ${hasUnsavedChanges ? 'save-changes-btn-active' : ''}`} 
            onClick={saveChanges}
          >
            {hasUnsavedChanges ? 'Save Changes' : 'Changes Saved'}
          </button>
        </div>
        <div className="search-break-type-container">
          <input
            type="text"
            className="search-input"
            placeholder="Search..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <select
            className="break-type-dropdown"
            value={selectedBreakType || ''}
            onChange={e => setSelectedBreakType(e.target.value === '' ? null : e.target.value)}
          >
            <option value="">Prioritize Break Type</option>
            {breakTypes.map(type => <option key={type} value={type}>Prioritize {type}</option>)}
          </select>
        </div>
        <table className="break-data-table">
          <thead>
            <tr>
              {headers.map(header => (
                <th key={header}>{header}</th>
              ))}
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {['Unresolved', 'In-Progress', 'Resolved'].map(status => (
              <React.Fragment key={status}>
                <tr className={`status-row status-row-${status.toLowerCase().replace(/ /g, '-')}`}>
                  <td colSpan={Object.values(headers).length + 1} className="status-row" style={{ fontWeight: 'bold'}}>
                    {status}
                    <button className="recenttable-dropdown-button" onClick={() => {
                      switch (status) {
                        case 'Unresolved': toggleUnresolvedCollapse(); break;
                        case 'In-Progress': toggleInProgressCollapse(); break;
                        case 'Resolved': toggleResolvedCollapse(); break;
                        default: // Handle unexpected status, if necessary
                      }
                    }}>
                      {((status === 'Unresolved' && isUnresolvedCollapsed) ||
                        (status === 'In-Progress' && isInProgressCollapsed) ||
                        (status === 'Resolved' && isResolvedCollapsed)) ? '▼' : '▲'}
                    </button>
                  </td>
                </tr>
                {!(
                  (status === 'Unresolved' && isUnresolvedCollapsed) || 
                  (status === 'In-Progress' && isInProgressCollapsed) || 
                  (status === 'Resolved' && isResolvedCollapsed)
                ) && groupedBreaks[status] && groupedBreaks[status].map((breakItem, index) => (
                  <React.Fragment key={breakItem.id}>
                    {breakItem.entries.map((entry, entryIdx) => (
                      <tr key={entryIdx} className={`break-pair-${breakItem.id}`}>
                        {Object.values(headers).map(header => (
                          <td key={header}>
                            {entry[header] !== null && entry[header] !== undefined && entry[header] !== '' ? entry[header] : ''}
                            {(entryIdx === 0 || entryIdx === 1) && hasDiscrepancy(breakItem.entries[0], breakItem.entries[1], header) &&
                              <FaExclamationCircle style={{ color: '#ff0000', marginLeft: '5px', marginBottom: '3px' }} />
                            }
                          </td>           
                        ))}
                        {entryIdx === 0 && (
                          <td rowSpan={breakItem.entries.length} style={{ paddingLeft: '1em', paddingBottom: '15px' }}>
                            <div className="status-info-container">
                              {breakItem.aggregationComplete1 === "True" && breakItem.aggregationComplete2 === "True" && (
                                <button 
                                  className="original-entries-toggle" 
                                  onClick={() => toggleOriginalEntries(breakItem.id)}
                                >
                                  {shownOriginalEntriesIds.has(breakItem.id) ? 'Hide Original Entries' : 'Show Original Entries'}
                                </button>
                              )}
                              <select
                                className="status-dropdown"
                                value={breakItem.status}
                                onChange={e => updateEditableData(breakItem.id, 'status', e.target.value)}
                                style={{ backgroundColor: '#ffffff' }}
                              >
                                <option value="Unresolved">Unresolved</option>
                                <option value="In-Progress">In-Progress</option>
                                <option value="Resolved">Resolved</option>
                              </select>
  
                              <CgMoreO 
                                className="info-icon"
                                role="button"
                                tabIndex="0"
                                aria-label="Toggle info"
                                onClick={() => toggleInfoCard(breakItem.id)}  // Pass the correct ID here
                                style={{ color: getInfoIconColor(breakItem.status) }}
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                    {shownInfoCardIds.has(breakItem.id) && (
                      <tr>
                        <td colSpan={Object.values(headers).length + 1} style={{ padding: '10px 0' }}>
                          <div className="info-card">
                            <strong>Suggestion:</strong>
                            <span
                              contentEditable={true}
                              onBlur={(e) => handleBlur(breakItem.id, 'suggestion', e.currentTarget.textContent)}
                            >
                              {breakItem.suggestion}
                            </span>
                            <div style={{ marginTop: '10px' }}>
                              <strong>Notes:</strong>
                              <span
                                contentEditable={true}
                                onBlur={(e) => handleBlur(breakItem.id, 'notes', e.currentTarget.textContent)}
                              >
                                {breakItem.notes}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                    {shownOriginalEntriesIds.has(breakItem.id) && (
                      <React.Fragment>
                        {/* Render only df1 original entries if df2 is empty */}
                        {JSON.parse(breakItem.original_entries_df2).every(entry => !Object.values(entry).some(value => value)) ? (
                          <React.Fragment>
                            <tr className="side-label">
                              <td colSpan={Object.values(headers).length} style={{ textAlign: 'left', fontWeight: 'bold' }}>{breakItem.entries[0].File}</td>
                            </tr>
                            {JSON.parse(breakItem.original_entries_df1).map((entry, entryIdx) => (
                              <tr key={entryIdx} className={`original-entry-pair-${breakItem.id}`}>
                                {Object.values(headers).map(header => (
                                  <td key={header}>
                                    {entry[header] !== null && entry[header] !== undefined && entry[header] !== '' ? entry[header] : ''}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <tr className="side-label">
                              <td colSpan={Object.values(headers).length} style={{ textAlign: 'left', fontWeight: 'bold' }}>{breakItem.entries[0].File}</td>
                            </tr>
                            {JSON.parse(breakItem.original_entries_df1).map((entry, entryIdx) => (
                              <tr key={entryIdx} className={`original-entry-pair-${breakItem.id}`}>
                                {Object.values(headers).map(header => (
                                  <td key={header}>
                                    {entry[header] !== null && entry[header] !== undefined && entry[header] !== '' ? entry[header] : ''}
                                  </td>
                                ))}
                              </tr>
                            ))}
                            <tr className="side-label">
                              <td colSpan={Object.values(headers).length} style={{ textAlign: 'left', fontWeight: 'bold' }}>{breakItem.entries[1].File}</td>
                            </tr>
                            {JSON.parse(breakItem.original_entries_df2).map((entry, entryIdx) => (
                              <tr key={entryIdx} className={`original-entry-pair-${breakItem.id}`}>
                                {Object.values(headers).map(header => (
                                  <td key={header}>
                                    {entry[header] !== null && entry[header] !== undefined && entry[header] !== '' ? entry[header] : ''}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                    {/* Adding space between break entries */}
                    <tr className="break-entry-spacing">
                      <td colSpan={Object.values(headers).length + 1} style={{ padding: '10px 0' }}></td>
                    </tr>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  
  
  

  
  
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);  // Parse the timestamp
    
    const options = {
      month: 'short', 
      day: 'numeric',
      timeZone: 'America/New_York',  // Use Eastern Time (EST/EDT)
    };
  
    // Format the time in Eastern Time (EST/EDT)
    let formattedTime = date.toLocaleTimeString('en-US', { 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: true, 
      timeZone: 'America/New_York'  // Specify EST/EDT time zone
    });
    formattedTime = formattedTime.replace(/^0+/, ''); // Remove leading zeros
    
    // Return the date and time in EST/EDT
    return `${date.toLocaleDateString('en-US', options)} ${formattedTime}`;
  };
  



  return (
    <div className="recent-results-table">
      <h5 className="timestamp-heading">Last Ran {formatTimestamp(data.timestamp)}</h5>
      {
        // Pass data.all_breaks directly to renderMetricsMessage
        renderMetricsMessage(data.metrics, data.all_breaks) && 
        <h5 className="metric-heading">{renderMetricsMessage(data.metrics, data.all_breaks)}</h5>
      }
      {renderBreakData()}
    </div>
  );
  
  
}



export default memo(RecentResultsTable);
