import React, { useState, useRef, useEffect } from 'react';
import { Storage, Auth, API } from 'aws-amplify';
import axios from 'axios';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import './FilesTab.css';
import { HiUpload } from "react-icons/hi";

function FilesTab({ configData, title, filename1: initialFilename1, filename2: initialFilename2 }) {
    const [selectedFilesA, setSelectedFilesA] = useState([]);
    const [selectedFilesB, setSelectedFilesB] = useState([]);
    const [sideAFiles, setSideAFiles] = useState([]);
    const [sideBFiles, setSideBFiles] = useState([]);
    const [filename1, setFilename1] = useState(initialFilename1);
    const [filename2, setFilename2] = useState(initialFilename2);
    const [selectedSideAFile, setSelectedSideAFile] = useState(null);
    const [selectedSideAFileDate, setSelectedSideAFileDate] = useState(null);  // New state for date
    const [selectedSideBFile, setSelectedSideBFile] = useState(null);
    const [selectedSideBFileDate, setSelectedSideBFileDate] = useState(null);  // New state for date
    const fileInputA = useRef();
    const fileInputB = useRef();
    const validFileTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const maxFileSize = 500000 * 1024 * 1024; // 5MB, for example

    const isValidFileType = (file) => validFileTypes.includes(file.type);
    const isValidFileSize = (file) => file.size <= maxFileSize;

    const getPresignedUrl = async (file, side) => {
        const user = await Auth.currentAuthenticatedUser();
        const organization = user.attributes['custom:organization'];
    
        const apiName = 'GetS3Url';
        const path = '/gets3url';
        const init = {
            body: {
                fileName: file.name,
                fileType: file.type,
                organization,
                folderName: `${title}/${side}`  // Updated to specify the side folder
            },
        };
    
        try {
            const response = await API.post(apiName, path, {
                ...init,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(init.body),
            });
    
            const presignedUrl = response.url;
            return presignedUrl;
        } catch (error) {
        
            throw error;
        }
    };
    

    const handleFileChangeA = (event) => {
        const file = event.target.files[0];
        if (file && isValidFileType(file) && isValidFileSize(file)) {
            setSelectedFilesA([file]);
        }
    };
    
    const handleFileChangeB = (event) => {
        const file = event.target.files[0];
        if (file && isValidFileType(file) && isValidFileSize(file)) {
            setSelectedFilesB([file]);
        }
    };
    
    

    const handleDragOver = (event) => {
        event.preventDefault(); // This prevents the browser's default drag-and-drop action
    };

    const handleDropA = (event) => {
        event.preventDefault();
        const files = [];
        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                if (event.dataTransfer.items[i].kind === 'file') {
                    const file = event.dataTransfer.items[i].getAsFile();
                    if (isValidFileType(file) && isValidFileSize(file)) {
                        files.push(file);
                    }
                }
            }
        } else {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                if (isValidFileType(event.dataTransfer.files[i]) && isValidFileSize(event.dataTransfer.files[i])) {
                    files.push(event.dataTransfer.files[i]);
                }
            }
        }
        setSelectedFilesA(prevFiles => [...prevFiles, ...files]);
    };

    const handleDropB = (event) => {
        event.preventDefault();
        const files = [];
        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                if (event.dataTransfer.items[i].kind === 'file') {
                    const file = event.dataTransfer.items[i].getAsFile();
                    if (isValidFileType(file) && isValidFileSize(file)) {
                        files.push(file);
                    }
                }
            }
        } else {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                if (isValidFileType(event.dataTransfer.files[i]) && isValidFileSize(event.dataTransfer.files[i])) {
                    files.push(event.dataTransfer.files[i]);
                }
            }
        }
        setSelectedFilesB(prevFiles => [...prevFiles, ...files]);
    };

    const uploadFileToS3 = async (file, url) => {
        try {
            const response = await axios({
                method: 'put',
                url: url,
                data: file,
                headers: {
                    'Content-Type': file.type,
                },
            });
        } catch (error) {
        }
    };

    const handleUploadA = async () => {
        for (const file of selectedFilesA) {
            try {
                // Retrieve the pre-signed URL from your backend
                const url = await getPresignedUrl(file, 'SideA'); // Pass the side as 'SideA'
    
                // Check if the URL is defined
                if (url) {
                    // Upload the file directly to the pre-signed URL
                    await uploadFileToS3(file, url);
    
                    // Save changes after uploading the file
                    const currentUser = await Auth.currentAuthenticatedUser();
                    const userOrganization = currentUser.attributes['custom:organization'];
    
                    const updatedConfig = {
                        filename1: file.name, // Use the new file name
                        title: title,
                        organization: userOrganization
                    };
                    setFilename1(file.name);
                    await saveChanges(updatedConfig);
                    
                } else {
              
                }
            } catch (err) {
               
            }
        }
    
        // After the upload is complete, fetch the list of existing files again
        setSelectedFilesA([]);
        fetchExistingFiles(title);
        // You may also want to clear the selected files list
        
    };
    
    
    const handleUploadB = async () => {
        for (const file of selectedFilesB) {
            try {
                // Retrieve the pre-signed URL from your backend
                const url = await getPresignedUrl(file, 'SideB'); // Pass the side as 'SideB'
    
                // Check if the URL is defined
                if (url) {
                    // Upload the file directly to the pre-signed URL
                    await uploadFileToS3(file, url);
                    // Save changes after uploading the file
                    const currentUser = await Auth.currentAuthenticatedUser();
                    const userOrganization = currentUser.attributes['custom:organization'];
    
                    const updatedConfig = {
                        filename2: file.name, // Use the new file name
                        title: title,
                        organization: userOrganization
                    };
                    setFilename2(file.name);
                    await saveChanges(updatedConfig);
                } else {
               
                }
            } catch (err) {
              
            }
        }
    
        // After the upload is complete, fetch the list of existing files again
        setSelectedFilesB([]);
        fetchExistingFiles(title);
        // You may also want to clear the selected files list
        
    };


    
    

    const fetchExistingFiles = async (title, configData) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const organization = user.attributes['custom:organization'];
    
            const apiName = 'FetchS3Files';
            const path = '/fetchs3files';
            const response = await API.post(apiName, path, {
                body: {
                    title,
                    organization
                }
            });
            const files = response.files;
            if (Array.isArray(files)) {
                // Split files into SideA and SideB based on their keys
                const sideA = files.filter(file => file.key.startsWith(`${title}/SideA/`));
                const sideB = files.filter(file => file.key.startsWith(`${title}/SideB/`));
    
                setSideAFiles(sideA);
                setSideBFiles(sideB);
    
                // Automatically select the most recent file for SideA and SideB
      
            } else {
                throw new Error("Invalid response structure");
            }
        } catch (error) {
            
        }
    };
    
    useEffect(() => {
        fetchExistingFiles(title);
        
    }, [title]);

    const sideAOptions = sideAFiles
    .filter(file => !file.key.endsWith('/'))
    .map(file => ({ value: file.key, label: file.key.replace(`${title}/SideA/`, '') }));

const sideBOptions = sideBFiles
    .filter(file => !file.key.endsWith('/'))
    .map(file => ({ value: file.key, label: file.key.replace(`${title}/SideB/`, '') }));

    useEffect(() => {
        // Check if filename1 and filename2 exist in the options and set them as selected if they do
        const initialSideAFile = sideAFiles.find(file => file.key === `${title}/SideA/${filename1}`);
        const initialSideBFile = sideBFiles.find(file => file.key === `${title}/SideB/${filename2}`);
    
        if (initialSideAFile) {
            setSelectedSideAFile(initialSideAFile.key);
            setSelectedSideAFileDate(initialSideAFile.last_modified);
        }
    
        if (initialSideBFile) {
            setSelectedSideBFile(initialSideBFile.key);
            setSelectedSideBFileDate(initialSideBFile.last_modified);
        }
    }, [sideAFiles, sideBFiles, filename1, filename2, title]);


// Define the saveChanges function
const saveChanges = async (updatedConfig) => {
    try {
        const apiName = 'EditConfig';
        const path = `/edit/${updatedConfig.title}`;

        const bodyData = {
            filename1: updatedConfig.filename1,
            filename2: updatedConfig.filename2,
            title: updatedConfig.title,
            organization: updatedConfig.organization
        };

        const response = await API.put(apiName, path, { body: bodyData });
       

        // Optionally handle post-save logic, e.g., updating state or notifying user
    } catch (error) {
      
    }
};

const handleSaveA = async () => {
    try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userOrganization = currentUser.attributes['custom:organization'];

        const updatedConfig = {
            filename1: selectedSideAFile ? selectedSideAFile.split('/').pop() : null, // Extract file name
            title: title,
            organization: userOrganization
        };

        await saveChanges(updatedConfig);
    } catch (error) {
       
    }
};

const handleSaveB = async () => {
    try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userOrganization = currentUser.attributes['custom:organization'];

        const updatedConfig = {
            filename2: selectedSideBFile ? selectedSideBFile.split('/').pop() : null, // Extract file name
            title: title,
            organization: userOrganization
        };

        await saveChanges(updatedConfig);
    } catch (error) {
      
    }
};





    




return (
    <div className="files-tab-container">
        <div className="file-selection">
            <div className="side-selection">
                <div className="sideA">
                    <div className="title-and-save">
                        <h3>Side A</h3>
                        <button className="save-btn" onClick={handleSaveA}>
                            Save
                        </button>
                    </div>



                    <Select
                        options={sideAOptions}
                        value={sideAOptions.find(option => option.value === selectedSideAFile)}
                        onChange={selectedOption => {
                            if (selectedOption && !selectedOption.value.endsWith('/')) {
                                setSelectedSideAFile(selectedOption.value);
                                const selectedFile = sideAFiles.find(file => file.key === selectedOption.value);
                                if (selectedFile) {
                                    setSelectedSideAFileDate(selectedFile.last_modified);
                                }
                                
                            } else {
                                setSelectedSideAFile(null);
                                setSelectedSideAFileDate(null);
                            }
                        }}
                        placeholder="Select or type to search Side A files"
                    />
                    {selectedSideAFile && !selectedSideAFile.endsWith('/') && selectedSideAFileDate && (
                        <div className="file-date">
                            Uploaded {new Date(selectedSideAFileDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}, {new Date(selectedSideAFileDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}
                        </div>
                    )}

                    <div className="upload-section">
                        <div className="drag-drop-section" onDragOver={handleDragOver} onDrop={handleDropA}>
                            Drag and Drop files here or <span className="file-select-link" onClick={() => fileInputA.current.click()}>select them</span>
                        </div>
                        <input 
                            type="file"
                            ref={fileInputA}
                            className="file-input"
                            onChange={handleFileChangeA}
                        />
                        {selectedFilesA.length > 0 && (
                            <ul className="files-list">
                                {selectedFilesA.map((file, index) => (
                                    <li key={index} className="file-item">
                                        <span className="file-name">{file.name}</span>
                                        <button className="upload-btn" onClick={handleUploadA}>
                                            <HiUpload />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                        
                    </div>









                </div>
            </div>
            



            <div className="side-selection">
                <div className="sideB">
                    <div className="title-and-save">
                        <h3>Side B</h3>
                        <button className="save-btn" onClick={handleSaveB}>
                            Save
                        </button>
                    </div>
                    <Select
                        options={sideBOptions}
                        value={sideBOptions.find(option => option.value === selectedSideBFile)}
                        onChange={selectedOption => {
                            if (selectedOption && !selectedOption.value.endsWith('/')) {
                                setSelectedSideBFile(selectedOption.value);
                                const selectedFile = sideBFiles.find(file => file.key === selectedOption.value);
                                if (selectedFile) {
                                    setSelectedSideBFileDate(selectedFile.last_modified);
                                }
                             
                            } else {
                                setSelectedSideBFile(null);
                                setSelectedSideBFileDate(null);
                            }
                        }}
                        placeholder="Select or type to search Side B files"
                    />
                    {selectedSideBFile && !selectedSideBFile.endsWith('/') && selectedSideBFileDate && (
                        <div className="file-date">
                            Uploaded {new Date(selectedSideBFileDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}, {new Date(selectedSideBFileDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}
                        </div>
                    )}

                    <div className="upload-section">
                        <div className="drag-drop-section" onDragOver={handleDragOver} onDrop={handleDropB}>
                            Drag and Drop files here or <span className="file-select-link" onClick={() => fileInputB.current.click()}>select them</span>
                        </div>
                        <input 
                            type="file"
                            ref={fileInputB}
                            className="file-input"
                            onChange={handleFileChangeB}
                        />
                        {selectedFilesB.length > 0 && (
                            <ul className="files-list">
                                {selectedFilesB.map((file, index) => (
                                    <li key={index} className="file-item">
                                        <span className="file-name">{file.name}</span>
                                        <button className="upload-btn" onClick={handleUploadB}>
                                            <HiUpload />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>








                </div>
            </div>
        </div>
    </div>
);


    
}

export default FilesTab;
