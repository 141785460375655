

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Auth, API, Storage, Amplify} from 'aws-amplify';
import ConfigurationTab from './ConfigurationTab'; 
import TransformationsTab from './TransformationsTab';
import PreviousBreaksTab from './PreviousBreaksTab'; 
import FileCheckDisplay from './FileCheckDisplay'; 
import RecentResultsTable from './RecentResultsTable'; 
import StitchFunctionTab from './StitchFunctionTab';
import './ReconciliationPage.css';
import FilesTab from './FilesTab';
import SftpTab from './SftpTab';
import Header from './Header'; 
import { TfiReload } from 'react-icons/tfi';
import ReconciliationMetricsDisplay from './ReconciliationMetricsDisplay.js';  
import './ReconciliationMetricsDisplay.css'

function ReconciliationPage() {
  const { id } = useParams();

  // State variables
  const [allResultsLambdaResponses, setAllResultsLambdaResponses] = useState({});
  const [mostRecentLambdaResponses, setMostRecentLambdaResponses] = useState({});
  const [allTimestamps, setAllTimestamps] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAllResults, setShowAllResults] = useState({});
  const [allResultsShowTable, setAllResultsShowTable] = useState({});
  const [selectedTimestamp, setSelectedTimestamp] = useState(null);
  const [configData, setConfigData] = useState(null);
  const [filesFetched, setFilesFetched] = useState(false);
  const [resultsFetched, setResultsFetched] = useState(false);


  // State variables for editing
  
  const [mostRecentTimestamp, setMostRecentTimestamp] = useState(null);
  const [recentResult, setRecentResult] = useState({});
  const [currentTab, setCurrentTab] = useState('Current Breaks'); // This will set 'Current Breaks' as the default tab
  const [isConfigDropdownVisible, setIsConfigDropdownVisible] = useState(false);

  const handleConfigurationClick = () => {
    setIsConfigDropdownVisible(!isConfigDropdownVisible);
  };


  const MAX_RETRIES = 5;
const RETRY_DELAY = 2000; // Delay in milliseconds

const fetchRecentResult = async (title) => {
  for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
    try {
      
      
      const currentUser = await Auth.currentAuthenticatedUser();
      const userOrganization = currentUser.attributes['custom:organization'];

      const requestBody = {
        title: title,
        organization: userOrganization,
      };

      const apiData = await API.post('LatestResults', `/newestres`, { body: requestBody });
      

      setIsLoading(false); // Set loading to false after successfully fetching data
      return apiData; // Data fetched successfully, return it
      
    } catch (error) {
     
      
      // If this was the last attempt, ensure loading is also set to false before rethrowing the error
      if (attempt === MAX_RETRIES) {
        setIsLoading(false); // Ensure loading state is updated even in case of final failure
        throw error;
      }

      // Wait for RETRY_DELAY milliseconds before retrying
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
    }
  }
};




const fetchAndSetRecentResult = async () => {
  
  setResultsFetched(false);

  if (!id) {
    return;
  }

  try {
    const data = await fetchRecentResult(id);
    setRecentResult(data);
    setResultsFetched(true); // Set resultsFetched to true after recent results are fetched
  } catch (error) {
   
  } finally {
    setIsLoading(false);
  }
};

useEffect(() => {
  if (id) {
    fetchConfigData(id); // Fetch config only on page load or id change
  }
}, [id]);

useEffect(() => {
  if (id) {
    fetchAndSetRecentResult(); // Fetch recent result separately
  }
}, [id]);


// Fetching all Previous Results

    // Function to fetch data from DynamoDB
    const fetchDataFromDynamoDB = async (title, timestamp, type = "allResults") => {
      try {
      
        const user = await Auth.currentAuthenticatedUser();
        const organization = user.attributes['custom:organization'];
        
        const requestBody = {
          title: title,
          timestamp: timestamp,
          organization: organization
        };
    
       
        const response = await API.post('fetchResultsAPI', `/fetchres`, { body: requestBody });
        
    
        if (response.data && Array.isArray(response.data)) {
          const parsedData = response.data.map(item => ({
            ...item,
            timestamp: item.timestamp,
            break_counts: JSON.parse(item.break_counts),
            merged_breaks: JSON.parse(item.merged_breaks),
            metrics: JSON.parse(item.metrics),
            set1_breaks: JSON.parse(item.set1_breaks),
            set2_breaks: JSON.parse(item.set2_breaks),
          }));
    
          if (type === "allResults") {
            setAllResultsLambdaResponses(prevState => ({ ...prevState, [title]: parsedData }));
          } else {
            setMostRecentLambdaResponses(prevState => ({ ...prevState, [title]: parsedData }));
          }
          
        } else {
        
        }
      } catch (err) {
       
      }
      
    };
    
    const sendDataToLambda = async (title) => {
      setIsSending(true);
      try {
        const user = await Auth.currentAuthenticatedUser();
        const organization = user.attributes['custom:organization'];
    
        // API call to start the reconciliation process
        const response = await API.post('reconcile', '/reconcile', {
          body: {
            title: title,
            organization: organization,
          },
        });
    
        if (response) {
          // Fetch the updated recent result data after receiving the response
          await fetchAndSetRecentResult();
        }
    
        // Fetch data from DynamoDB and all timestamps (if needed)
        // await fetchDataFromDynamoDB(id, selectedTimestamp);
        // await fetchAllTimestampsForTitle(id);
        //Replacing Above 2 with the next line to fix double load

        await fetchAndSetRecentResult(); // Only fetch recent result once

    
        // Update the UI to show that the reconciliation is complete
        setIsSending(false);
        setShowAllResults((prevState) => ({
          ...prevState,
          [title]: true, // Ensure this stays true after results are fetched
        }));
      } catch (err) {
        // Handle errors
        if (err.response && err.response.status === 504) {
          alert('Reconciling files, this will take a few minutes, please reload the page');
        } else {
          alert('Configuration error, please update configuration');
        }
        setIsSending(false);
      }
    };
    
  
  
    
  
    const fetchAllTimestampsForTitle = async (title) => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const organization = user.attributes['custom:organization'];
        
    
        const requestBody = {
          title: title,
          organization: organization
        };
    
        const response = await API.post('fetchtime', `/fetchtime`, {
          body: requestBody
        });
        
    
        // Access the timestamps directly from the response
        const allTimestampData = response;
       
    
        const sortedTimestamps = allTimestampData.sort((a, b) => new Date(b) - new Date(a));
    
        if (!selectedTimestamp) {
          setSelectedTimestamp(sortedTimestamps[0]);
        }
    
        if (sortedTimestamps && sortedTimestamps.length > 0) {
          setMostRecentTimestamp(sortedTimestamps[0]);
        }
    
        setAllTimestamps(prevState => ({ ...prevState, [title]: sortedTimestamps }));
      } catch (err) {

      }
    };
    
    
    
    
  
  
    // Function to handle showing all results
    const handleShowAllResults = (title) => {
      
      setShowAllResults(prevState => ({
        ...prevState,
        [title]: true,
      }));
      fetchAllTimestampsForTitle(title);
    };
  

  
    const fetchConfigData = async (title) => {
      setIsLoading(true);
      setFilesFetched(false);
      
      try {
        const user = await Auth.currentAuthenticatedUser();
        const organization = user.attributes['custom:organization'];
        const requestBody = { title, organization };
    
        const response = await API.post('fetchConfigAPI', '/fetchconfig', { body: requestBody });
        if (response.item) {
          setConfigData(response.item); // Update state with fetched configuration data
          setFilesFetched(true); // Set filesFetched to true after config data is fetched
        } else {
        
        }
      } catch (err) {
      
      } finally {
        setIsLoading(false);
      }
    };






    const [allExcelUrls, setAllExcelUrls] = useState({});
    useEffect(() => {
      handleShowAllResults(id);
      if (selectedTimestamp) {
          setTimeout(() => {
              fetchDataFromDynamoDB(id, selectedTimestamp);
              getExcelDownloadUrl(selectedTimestamp).then(url => {
                  setAllExcelUrls(prev => ({ ...prev, [selectedTimestamp]: url }));
              });
          }, 1000);
      }
      fetchConfigData(id);
  }, [id, selectedTimestamp]);
  

   
    const getExcelDownloadUrl = async (timestamp) => {
      const fileKey = `reconciliation_results_${timestamp}.xlsx`;
      try {
        const signedUrl = await Storage.get(fileKey, { expires: 300 });  // URL will be valid for 5 minutes
        return signedUrl;
      } catch (error) {

        return null;
      }
    }
    

  useEffect(() => {

    
    handleShowAllResults(id);
    if (selectedTimestamp) {
      setTimeout(() => {
        fetchDataFromDynamoDB(id, selectedTimestamp);
      }, 1000);
    }
    fetchConfigData(id);
  }, [id, selectedTimestamp]);


  useEffect(() => {
    if (mostRecentTimestamp) {
      fetchDataFromDynamoDB(id, mostRecentTimestamp);
    }
  }, [mostRecentTimestamp]);


  const [excelUrl, setExcelUrl] = useState(null);
  useEffect(() => {
    if (selectedTimestamp) {
        getExcelDownloadUrl(selectedTimestamp).then(url => setExcelUrl(url));
    }
}, [selectedTimestamp]);

const [areFilesPresent, setAreFilesPresent] = useState(false);

// Define the handleFileStatusChange function
const handleFileStatusChange = (fileStatus) => {
    setAreFilesPresent(fileStatus);
};

const switchToFilesTab = () => {
  setCurrentTab('Files');
};





if (isLoading || !filesFetched || !resultsFetched) {
  return (
    <div className="loading">
      <div className="ai-animation">
        <div className="circle circle-1"></div>
        <div className="circle circle-2"></div>
      </div>
      Loading Results...
    </div>
  );
}


if (Object.keys(recentResult).length === 0 && !isLoading) {
  return (
    <div className="recon-parent-container">
      <Header />
      <h2 className="config-name">Configuration: {id}</h2>

      <div className="recon-tab-container">
      <button onClick={() => setCurrentTab('Current Breaks')} className={`recon-tab ${currentTab === 'Current Breaks' ? 'recon-tab-active' : ''}`}>Current Breaks</button>
      <button onClick={() => setCurrentTab('Previous Breaks')} className={`recon-tab ${currentTab === 'Previous Breaks' ? 'recon-tab-active' : ''}`}>Previous Runs</button>
      
      <div className="config-dropdown">
        <button onClick={handleConfigurationClick} className={`recon-tab ${currentTab === 'Configuration' ? 'recon-tab-active' : ''}`}>
          Configuration ⯆
        </button>
        {isConfigDropdownVisible && (
          <div className="config-dropdown-menu">
            <button onClick={() => { setCurrentTab('Configuration'); setIsConfigDropdownVisible(false); }} className={`config-dropdown-item ${currentTab === 'Configuration' ? 'recon-tab-active' : ''}`}>Data Settings</button>
            <button onClick={() => { setCurrentTab('Transformations'); setIsConfigDropdownVisible(false); }} className={`config-dropdown-item ${currentTab === 'Transformations' ? 'recon-tab-active' : ''}`}>Transformations</button>
            <button onClick={() => { setCurrentTab('Stitch Data'); setIsConfigDropdownVisible(false); }} className={`config-dropdown-item ${currentTab === 'Stitch Data' ? 'recon-tab-active' : ''}`}>Stitch Data</button>
            <button onClick={() => { setCurrentTab('Files'); setIsConfigDropdownVisible(false); }} className={`config-dropdown-item ${currentTab === 'Files' ? 'recon-tab-active' : ''}`}>File Upload</button>
            <button onClick={() => { setCurrentTab('Sftp'); setIsConfigDropdownVisible(false); }} className={`config-dropdown-item ${currentTab === 'Sftp' ? 'recon-tab-active' : ''}`}>Sftp</button>
            <button onClick={() => { setCurrentTab('Automate Runs'); setIsConfigDropdownVisible(false); }} className={`config-dropdown-item ${currentTab === 'Automate Runs' ? 'recon-tab-active' : ''}`}>Schedule Runs</button>
          </div>
        )}
      </div>

      <button onClick={() => setCurrentTab('Audit Trail')} className={`recon-tab ${currentTab === 'Audit Trail' ? 'recon-tab-active' : ''}`}>Audit Trail</button>
    </div>

{/* Current Breaks Tab */}
{currentTab === 'Current Breaks' && (
  <div className="recon-current-breaks">
    <div className="no-results-message">
      {!areFilesPresent ? (
        <>
          <h2>Files Are Missing</h2>
          <h3>Please ensure all required files are uploaded before running reconciliation.</h3>
          <button onClick={() => setCurrentTab('Files')} className="send-button">
            Upload Files
          </button>
          <button onClick={() => setCurrentTab('Configuration')} className="send-button">
            View File Config
          </button>
          <button onClick={() => window.location.reload()} className="page-refresh-button">
            <TfiReload size={24} />
          </button>
        </>
      ) : (
        <>
          <h2>No Reconciliation Results for this Configuration</h2>
          <h3>Please Run Reconciliation</h3>
          <button onClick={() => sendDataToLambda(id)} disabled={isSending} className="send-button">
            {isSending ? 'Processing...' : 'Run Reconciliation'}
          </button>
          <button onClick={() => window.location.reload()} className="page-refresh-button">
            <TfiReload size={24} />
          </button>
        </>
      )}
    </div>
    <FileCheckDisplay
      configData={configData}
      onFileStatusChange={handleFileStatusChange}
      setIsLoading={setIsLoading} // Pass setIsLoading as a prop
    />
  </div>
)}


{currentTab === 'Previous Breaks' && (
    <PreviousBreaksTab title={id} />
)}


      {/* Configuration Tab */}
      {currentTab === 'Configuration' && (
        <ConfigurationTab configData={configData} onConfigEdited={(newConfig) => { /* Handle the updated config if needed */ }} />
      )}

        {/* Transformations Tab */}
  {currentTab === 'Transformations' && (
          <TransformationsTab
            configData={configData}
          
          />
        )}

{currentTab === 'Stitch Data' && (
  <StitchFunctionTab configData={configData} />
)}


      {/* Files Tab */}
      {currentTab === 'Files' && (
        <FilesTab configData={configData} title={id} filename1={configData?.filename1} filename2={configData?.filename2} />
      )}

      {/* Sftp Tab */}
      {currentTab === 'Sftp' && (
        <SftpTab configData={configData} title={id} />
      )}
    </div>
  );
} else {
  

return (
  <div className="recon-parent-container">
  <Header />
  <h2 className="config-name">Configuration: {id}</h2>

 

    <div className="recon-tab-container">
      <button onClick={() => setCurrentTab('Current Breaks')} className={`recon-tab ${currentTab === 'Current Breaks' ? 'recon-tab-active' : ''}`}>Current Breaks</button>
      <button onClick={() => setCurrentTab('Previous Breaks')} className={`recon-tab ${currentTab === 'Previous Breaks' ? 'recon-tab-active' : ''}`}>Previous Runs</button>
      
      <div className="config-dropdown">
        <button onClick={handleConfigurationClick} className={`recon-tab ${currentTab === 'Configuration' ? 'recon-tab-active' : ''}`}>
          Configuration ⯆
        </button>
        {isConfigDropdownVisible && (
          <div className="config-dropdown-menu">
            <button onClick={() => { setCurrentTab('Configuration'); setIsConfigDropdownVisible(false); }} className={`config-dropdown-item ${currentTab === 'Configuration' ? 'recon-tab-active' : ''}`}>Data Settings</button>
            <button onClick={() => { setCurrentTab('Transformations'); setIsConfigDropdownVisible(false); }} className={`config-dropdown-item ${currentTab === 'Transformations' ? 'recon-tab-active' : ''}`}>Transformations</button>
            <button onClick={() => { setCurrentTab('Stitch Data'); setIsConfigDropdownVisible(false); }} className={`config-dropdown-item ${currentTab === 'Stitch Data' ? 'recon-tab-active' : ''}`}>Stitch Data</button>
            <button onClick={() => { setCurrentTab('Files'); setIsConfigDropdownVisible(false); }} className={`config-dropdown-item ${currentTab === 'Files' ? 'recon-tab-active' : ''}`}>File Upload</button>
            <button onClick={() => { setCurrentTab('Sftp'); setIsConfigDropdownVisible(false); }} className={`config-dropdown-item ${currentTab === 'Sftp' ? 'recon-tab-active' : ''}`}>Sftp</button>
          </div>
        )}
      </div>

      <button onClick={() => setCurrentTab('Audit Trail')} className={`recon-tab ${currentTab === 'Audit Trail' ? 'recon-tab-active' : ''}`}>Audit Trail</button>
    </div>






  {isLoading && (
    <div className="loading">
        <div className="ai-animation">
            {/* You can add more elements here for complex animations */}
        </div>
        Loading AI Analysis...
    </div>
)}







        {/* Current Breaks Tab */}
{currentTab === 'Current Breaks' && (
    <div className="recon-current-breaks">
        {/* Metrics Display */}
       
       
        <ReconciliationMetricsDisplay
  recentResult={recentResult}
  configData={configData}
  filename1={configData?.filename1}
  filename2={configData?.filename2}
/>

                
        {recentResult && (
            <div className="recent-results">




            

<RecentResultsTable 
  data={recentResult}
  isSending={isSending}
  onSendToLambda={() => sendDataToLambda(id)}
  excelUrl={excelUrl}
  configData={configData}
  switchToFilesTab={switchToFilesTab}
/>


             

            </div>
        )}
    </div>
)}

   

 {/* Previous Breaks Tab */}
{currentTab === 'Previous Breaks' && (
    <PreviousBreaksTab title={id} />
)}

      {/* Configuration Tab */}
      {currentTab === 'Configuration' && (
    <ConfigurationTab configData={configData} title={id} onConfigEdited={(newConfig) => { /* Handle the updated config if needed */ }} />
)}

  {/* Transformations Tab */}
  {currentTab === 'Transformations' && (
          <TransformationsTab
            configData={configData}
          
          />
        )}

{currentTab === 'Stitch Data' && (
  <StitchFunctionTab configData={configData} />
)}

 {/* Files Tab */}
 {currentTab === 'Files' && (
    <FilesTab 
        configData={configData} 
        title={id} 
        filename1={configData?.filename1} 
        filename2={configData?.filename2} 
    />
 )}

      {/* Sftp Tab */}
      {currentTab === 'Sftp' && (
        <SftpTab configData={configData} title={id} />
      )}





  </div>
);


}


}

export default ReconciliationPage;




