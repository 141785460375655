import React, { useState, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import Logo from './FastReconLogo.png'; // Importing the logo
import './CustomAuth.css'; // Importing the CSS
import { QRCodeSVG } from 'qrcode.react'; // Importing the QRCodeSVG component

function CustomAuth({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [user, setUser] = useState(null);
    const [otp, setOtp] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [showQRCodeSetup, setShowQRCodeSetup] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');

    // Use useRef to store the Keycloak instance
    const handleCognitoLogin = () => {
        // Redirect to the Cognito hosted UI
        window.location.href = 'https://fastrecon-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=57a9k0jdk2s51c357itu8q92db&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F';
    };
    

    const signIn = async () => {
        if (username.length > 32 || password.length > 32) {
            alert('Username and password must not exceed 32 characters.');
            return;
        }

        try {
            
            const user = await Auth.signIn(username, password);
            setUsername(username);
            
            setUser(user);
            handleAuthState(user, username);
        } catch (error) {
            
            if (error.code === 'NotAuthorizedException') {
                alert('Incorrect username or password. Please try again.');
            } else {
                alert('An error occurred during sign in. Please try again.');
            }
        }
    };

    const handleAuthState = async (user, username) => {
        
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            
            setShowQRCodeSetup(false);
        } else if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            
            setShowQRCodeSetup(false);
        } else if (user.challengeName === 'MFA_SETUP') {
            
            setShowQRCodeSetup(true);
            await setupMFA(user, username);
        } else if (!user.challengeName) {
            
            setShowQRCodeSetup(true);

            if (onLogin) onLogin();
        } else {
            
        }
    };

    const completeNewPassword = async () => {
        if (newPassword !== confirmPassword) {
            alert('Passwords do not match. Please try again.');
            return;
        }
        try {
            
            const updatedUser = await Auth.completeNewPassword(user, newPassword, {});
            
            setUser(null);
            setUsername('');
            setPassword('');
            setNewPassword('');
            setShowQRCodeSetup(false);
            alert("Your password has been updated. Please log in again with the new password.");
        } catch (error) {
            
            let errorMessage = 'An error occurred during password update.';
            if (error.code === 'InvalidPasswordException') {
                if (error.message.includes('Password not long enough')) {
                    errorMessage = 'Password is not long enough.';
                } else if (error.message.includes('Password must have uppercase characters')) {
                    errorMessage = 'Password must contain uppercase characters.';
                } else if (error.message.includes('Password must have numeric characters')) {
                    errorMessage = 'Password must contain numeric characters.';
                } else if (error.message.includes('Password must have symbol characters')) {
                    errorMessage = 'Password must contain symbol characters.';
                } else if (error.message.includes('Password must satisfy regular expression pattern')) {
                    errorMessage = 'Password does not meet the required pattern.';
                }
            }
            alert(errorMessage);
        }
    };

    const setupMFA = async (user, username) => {
        if (user.challengeName === 'MFA_SETUP') {
            try {
                
                const secretCode = await Auth.setupTOTP(user);

                const qrCodeUrl = `otpauth://totp/FastRecon:${username}?secret=${secretCode}&issuer=FastRecon (${username})`;
                setQrCode(qrCodeUrl);
                setShowQRCodeSetup(true); // Show QR code setup
            } catch (error) {
                
            }
        } else {
            
        }
    };

   // Function to verify OTP from MFA device
const verifyMFA = async () => {
    try {
        if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            // Verify the token for users who have already set up MFA
            await Auth.confirmSignIn(user, otp, 'SOFTWARE_TOKEN_MFA');
        } else if (user.challengeName === 'MFA_SETUP') {
            // Verify and set up MFA for users doing it for the first time
            await Auth.verifyTotpToken(user, otp);
            await Auth.setPreferredMFA(user, 'TOTP');
        } else {
            // Handle unknown MFA challenge
        }

        // Call onLogin callback if it exists
        if (onLogin) {
            onLogin();
        }
    } catch (error) {
        // Handle not authenticated situation
        if (error.code === 'NotAuthorizedException') {
            // User is not authenticated
        }
    }
};


    return (
        <div className="auth-container">
            <img src={Logo} alt="Fast Recon Logo" className="logo" />
            <h2 className="auth-title">Fast Recon</h2>

            {/* Existing code for username/password authentication */}
            {!user && (
                <>
                    <input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="auth-input"
                        placeholder="Username"
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="auth-input"
                        placeholder="Password"
                    />
                    <div className="button-group">
                    <button onClick={handleCognitoLogin} className="sso-button">SSO</button>

                        <button onClick={signIn} className="auth-button">Login</button>
                    </div>
                </>
            )}

            {/* MFA setup and verification */}
            {user && user.challengeName === 'SOFTWARE_TOKEN_MFA' && (
                <>
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="auth-input"
                        placeholder="Enter OTP from the app"
                    />
                    <button onClick={() => verifyMFA(user)} className="auth-button">Verify OTP</button>
                </>
            )}

            {user && showQRCodeSetup && (
                <>
                    <p>Please scan the QR Code with your Authenticator app and enter the OTP.</p>
                    <div className="mfa-qr-code">
                        {qrCode ? <QRCodeSVG value={qrCode} /> : <p>No QR Code Generated</p>}
                    </div>
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="auth-input"
                        placeholder="Enter OTP from the app"
                    />
                    <button onClick={() => verifyMFA(user)} className="auth-button">Verify OTP</button>
                </>
            )}

            {/* New password setup */}
            {user && user.challengeName === 'NEW_PASSWORD_REQUIRED' && (
                <>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="auth-input"
                        placeholder="New Password"
                    />
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="auth-input"
                        placeholder="Confirm New Password"
                    />
                    <button onClick={completeNewPassword} className="auth-button">Set New Password</button>
                </>
            )}
        </div>
    );
}

export default CustomAuth;
