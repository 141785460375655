import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Auth} from 'aws-amplify';
import CustomAuth from './CustomAuth';
import ConfigForm from './ConfigForm';
import HomePage from './HomePage';
import ReconciliationPage from './ReconciliationPage';
import FilesTab from './FilesTab';




function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        checkAuthState();
    }, []);

    async function checkAuthState() {
        try {
            await Auth.currentAuthenticatedUser();
            setIsAuthenticated(true);
        } catch {
            setIsAuthenticated(false);
        }
    }

    if (!isAuthenticated) {
        return <CustomAuth onLogin={checkAuthState} />;
    }

    return (
      <Router>
          <div>
              <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/config/new" element={<ConfigFormPage />} />
                  <Route path="/reconciliation/:id" element={<ReconciliationPageComponent />} />
                  <Route path="/files" element={<FilesTabPage />} /> 
              </Routes>
          </div>
      </Router>
  );
}

function ConfigFormPage() {
  return (
    <div>
      <ConfigForm />
    </div>
  );
}


function ReconciliationPageComponent() {
  return (
    <div>
      <ReconciliationPage />
    </div>
  );
}


function FilesTabPage() { 
  return (
    <div>
      <FilesTab />
    </div>
  );
}

export default App;
