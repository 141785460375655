import React, { useEffect } from 'react';
import { Auth, API } from 'aws-amplify';

function FileCheckDisplay({ configData, onFileStatusChange, setIsLoading }) {
    
    // Retry configuration
    const maxRetries = 5; // Maximum number of retry attempts
    const retryDelay = 1000; // Delay between retries in milliseconds

    useEffect(() => {
        const fetchAllFiles = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const organization = user.attributes['custom:organization'];
                const apiName = 'FetchS3Files';
                const path = '/fetchs3files';
                const title = configData.title

                const response = await API.post(apiName, path, {
                body: {
                    title,
                    organization
                      }
                 });

                
          
                return response.files;
            } catch (error) {
               
                throw new Error('Failed to fetch file list.');
            }
        };

        const checkFilePresence = async (filename, attempt = 0) => {
            try {
                
                const files = await fetchAllFiles();
                return files.some(file => file.key === filename);
            } catch (error) {
           
                if (attempt < maxRetries) {
             
                    await new Promise(resolve => setTimeout(resolve, retryDelay)); // Wait before retrying
                    return checkFilePresence(filename, attempt + 1); // Retry
                }
                return false; // Exceeded max retries
            }
        };

        const checkFiles = async () => {
    
            if (configData?.title && configData?.filename1 && configData?.filename2) {
                const filename1 = `${configData.title}/SideA/${configData.filename1}`;
                const filename2 = `${configData.title}/SideB/${configData.filename2}`;
                
               

                const filename1Exists = await checkFilePresence(filename1);
                const filename2Exists = await checkFilePresence(filename2);
                
                // Call the callback with true if both files are present, otherwise false
                onFileStatusChange(filename1Exists && filename2Exists);
                setIsLoading(false);
            } else {
                // Call the callback with false if either filename is not specified
                onFileStatusChange(false);
                setIsLoading(false);
            }
        };

        checkFiles();
    }, [configData, onFileStatusChange, setIsLoading]);

    // No need to return anything since this component now indirectly communicates via onFileStatusChange
    return null;
}

export default FileCheckDisplay;
