import React, { useState } from 'react';
import EditConfigForm from './EditConfigForm';
import { Auth, API } from 'aws-amplify';
import './ConfigurationTab.css';

function ConfigurationTab({ configData, onConfigEdited }) {
    const [editingConfig, setEditingConfig] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    console.log(configData);

    const openEditForm = (config) => {
        setEditingConfig({
            ...config,
            title: configData.title, // Ensure the title is included
        });
        setIsEditing(true);
    };

    const closeEditForm = () => {
        setEditingConfig(null);
        setIsEditing(false);
    };

    const saveChanges = async (updatedConfig) => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const userOrganization = currentUser.attributes['custom:organization'];
            const apiName = 'EditConfig';
            const path = `/edit/${updatedConfig.title}`;

            const transformedColumns = updatedConfig.columns.map((column) => ({
                nameInFile1: column.nameInFile1,
                nameInFile2: column.nameInFile2,
                standardName: column.standardName,
                dataType: column.dataType, // Ensure dataType is included
            }));

            const bodyData = {
                columns: transformedColumns,
                filename1: updatedConfig.filename1,
                filename2: updatedConfig.filename2,
                title: updatedConfig.title,
                type: updatedConfig.type,
                organization: userOrganization,
            };

            const response = await API.put(apiName, path, { body: bodyData });

            // Show success notification
            alert('Changes saved successfully!');

            // Optionally handle post-save logic, e.g., updating state or notifying user
        } catch (error) {
            // Show error notification
            alert('Error saving changes, please try again.');
        }
    };

    return (
        <div className="config-tab-container">
            {configData && (
                <div className="config-data">
                    <h2 className="config-data-title">Configuration Data</h2>
                    {Object.entries(configData).map(([key, value]) => {
                        // Skip displaying 'SideBStitch' and 'SideAStitch' and other stitch-related keys
                        if (
                            key !== 'SideBStitch' &&
                            key !== 'SideAStitch' &&
                            key !== 'columns' &&
                            key !== 'mappings' &&
                            key !== 'ignoreCharacters' &&
                            key !== 'rounding' &&
                            key !== 'textTransformations' &&
                            key !== 'numberTransformations' &&
                            key !== 'dateTransformations' &&
                            key !== 'aggregation'
                        ) {
                            return (
                                <div key={key} className="config-tab-data-item">
                                    <h3 className="data-item-title">
                                        {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </h3>
                                    <p className="data-item-detail">{typeof value === 'string' || typeof value === 'number' ? value : ''}</p>
                                </div>
                            );
                        }
                        return null;
                    })}

                    {/* Edit Configuration Button */}
                    <button
                        onClick={() => openEditForm(configData)}
                        className="edit-config-button"
                    >
                        Edit Configuration
                    </button>
                </div>
            )}

            {editingConfig && isEditing && (
                <EditConfigForm
                    className="edit-config-form"
                    editingConfig={editingConfig}
                    setEditingConfig={setEditingConfig}
                    saveChanges={saveChanges}
                    closeEditForm={closeEditForm}
                />
            )}
        </div>
    );
}

export default ConfigurationTab;
