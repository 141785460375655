import React, { useState, useEffect } from 'react';
import { Auth, API } from 'aws-amplify';
import { BsCheck2Circle } from 'react-icons/bs';
import { FiClock } from 'react-icons/fi';
import { FiAlertCircle } from 'react-icons/fi';
import { FaFile } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import './HomeConfigCard.css';


function HomeConfigCard({ title, type, filename1, filename2, allFileLists, filter, style, updateConfigStatus }) {


  const [isOpen, setIsOpen] = useState(false);
  const [mostRecentResult, setMostRecentResult] = useState(null);
  const [showTable, setShowTable] = useState('');
  const [file1Present, setFile1Present] = useState(false);
  const [file2Present, setFile2Present] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);




  


  const navigateToReconciliationPage = () => {
    navigate(`/reconciliation/${title}`);
  };
  


  const fetchMostRecentResult = async (title, maxRetries = 20, retryDelay = 2000) => {
    let attempt = 0;
    setLoading(true); // Start loading spinner
    let lastError = null; // Store the last error (in case needed for debugging later)
  
    while (attempt < maxRetries) {
      try {
        // Fetch the authenticated user
        const user = await Auth.currentAuthenticatedUser();
        const organization = user.attributes['custom:organization'];
  
        // Create request body to send to API
        const requestBody = {
          title: title,
          organization: organization,
        };
  
        // Fetch all timestamp data
        const allTimestampData = await API.post('fetchtime', `/fetchtime`, { body: requestBody });
  
        if (allTimestampData.length === 0) {
          setMostRecentResult(null); // No data found, set to null
          setLoading(false); // Stop loading spinner
          return; // Exit since no data is found
        }
  
        // Get the most recent timestamp
        let mostRecentTimestamp = allTimestampData.reduce((max, current) => {
          return new Date(current) > new Date(max) ? current : max;
        });
  
        // Prepare request body to fetch results
        const fetchResultsRequestBody = {
          title: title,
          timestamp: mostRecentTimestamp,
          organization: organization,
        };
  
        // Fetch the most recent reconciliation results
        const response = await API.post('fetchResultsAPI', `/fetchres`, { body: fetchResultsRequestBody });
  
        // Check if the response contains data and is in the expected format
        if (response.data && Array.isArray(response.data)) {
          const parsedData = response.data.map(item => ({
            ...item,
            timestamp: item.timestamp,
            break_counts: JSON.parse(item.break_counts),
            metrics: JSON.parse(item.metrics),
          }));
  
          // Set the most recent result
          setMostRecentResult(parsedData[0]);
          setLoading(false); // Stop loading spinner
          return; // Exit the loop after a successful fetch
        } else {
          // Handle unexpected response format
          setMostRecentResult(null); // Set to null in case of unexpected data format
          setLoading(false); // Stop loading spinner
          return; // Exit since there's nothing useful to retry
        }
      } catch (err) {
        lastError = err; // Store the error for potential debugging if needed
        // Continue retrying silently without resetting anything
        if (attempt + 1 === maxRetries) {
          setLoading(false); // Only stop loading after the last attempt fails
        }
        // Wait for retryDelay milliseconds before retrying
        await new Promise(resolve => setTimeout(resolve, retryDelay));
      }
      attempt++;
    }
  
    // After maxRetries, if still failing, stop loading
    setLoading(false);
  };
  

  
  
  

const matchedPercentage = mostRecentResult?.metrics.find(item => item.Metric === "Matched %")["Set 1:"];

const timestamp = mostRecentResult?.timestamp;


let formattedMatchedPercentage = matchedPercentage ? parseFloat(matchedPercentage).toFixed(1) + "%" : "N/A";

if (formattedMatchedPercentage === "100.0%") {
  formattedMatchedPercentage = "99.9%";
}

const unmatchedAmountSet1 = mostRecentResult?.metrics.find(item => item.Metric === "Unmatched Amount")["Set 1:"];
const unmatchedAmountSet2 = mostRecentResult?.metrics.find(item => item.Metric === "Unmatched Amount")["Set 2:"];
const highestUnmatchedAmount = (unmatchedAmountSet1 !== null && unmatchedAmountSet2 !== null) 
    ? (unmatchedAmountSet1 > unmatchedAmountSet2 ? unmatchedAmountSet1 : unmatchedAmountSet2)
    : (unmatchedAmountSet1 !== null ? unmatchedAmountSet1 : (unmatchedAmountSet2 !== null ? unmatchedAmountSet2 : "N/A"));

if (highestUnmatchedAmount === 0) {
  formattedMatchedPercentage = "100.0%";
}



  useEffect(() => {
    // Reset the state
    setIsOpen(false);
    setShowTable('');
    setMostRecentResult(null);

    // Then fetch the most recent result
    fetchMostRecentResult(title);
}, [title]);

const handleActionButtonClick = (event) => {
  
  event.stopPropagation(); // Prevent the click from being propagated to the parent
  navigateToReconciliationPage();
};


const [configData, setConfigData] = useState(null); 
const [isLoading, setIsLoading] = useState(false);


useEffect(() => {
  // Update file presence states based on passed filenames
  const file1Path = `${title}/SideA/${filename1}`;
  const file2Path = `${title}/SideB/${filename2}`;
  const file1Presence = allFileLists.includes(file1Path);
  const file2Presence = allFileLists.includes(file2Path);

  setFile1Present(file1Presence);
  setFile2Present(file2Presence);
}, [title, filename1, filename2, allFileLists]);



const reconStatus = mostRecentResult ? 'Recon Ran' : 'Files Present';
const filesReconciled = mostRecentResult && highestUnmatchedAmount === 0;


let cardColor;
let fileIcons = []; // <-- Renaming checkmarks to fileIcons


if (!file1Present || !file2Present) {
  fileIcons.push(<FaFile key="file1" color="lightgrey" size={24} />);
  
  cardColor = "white";
} else if (reconStatus === 'Files Present') {
  fileIcons.push(<FaFile key="file1" color="darkgrey" size={24} />);
  fileIcons.push(<FaFile key="file2" color="darkgrey" size={24} />);
  cardColor = "white";
} else {
    
    if (filesReconciled) {
      fileIcons.push(<FaFile key="file1" color="green" size={24} />);
        fileIcons.push(<FaFile key="file2" color="green" size={24} />);
        cardColor = "whitesh-green";
    } else {
      fileIcons.push(<FaFile key="file1" color="green" size={24} />);
        fileIcons.push(<FaFile key="file2" color="red" size={24} />);
        cardColor = "whitesh-red";
    }
}



function getStatusMessage() {
  if (!file1Present || !file2Present) {
    return "Files Missing";
  } else if (reconStatus === 'Files Present') {
    return "Files Present";
  } else if (filesReconciled) {
    return "Recon Successful";
  } else {
    return "To Review";
  }
}

const statusMessage = getStatusMessage();


function formatTimestamp(timestamp) {
  // Check if timestamp is undefined or null and return "N/A" if true
  if (!timestamp) {
    return "N/A";
  }

  const date = new Date(timestamp);
  const months = [
      'Jan', 'Feb', 'March', 'April', 'May', 'June', 'July',
      'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
  ];

  const monthName = months[date.getMonth()];
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = ('0' + date.getMinutes()).slice(-2); // add leading zero if necessary
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // if 0 then make it 12

  return `${monthName} ${day}, ${hours}:${minutes} ${ampm}`;
}





function getActionButtonLabel() {
  if (!file1Present || !file2Present) {
      return " Retrive Files";
  } else if (reconStatus === 'Files Present') {
      return "Run Recon";
  } else if (filesReconciled) {
      return "View Results";
  } else {
      return "Review Breaks";
  }
}

function formatType(type) {
  if (!type) return ''; // Return empty string if type is not provided
  const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1); // Capitalize the first letter
  return `${capitalizedType} Reconciliation`; // Append "Reconciliation" suffix
}

const actionButtonLabel = getActionButtonLabel();
const isGreyCheckCard = !file1Present || !file2Present || reconStatus === 'Files Present';

useEffect(() => {
  // Determine the status of the card and call updateConfigStatus
  const status = getStatusMessage();
  updateConfigStatus(title, status);
}, [file1Present, file2Present, mostRecentResult, reconStatus]);

useEffect(() => {
  fetchMostRecentResult(title);
  // Set loading to true initially when component mounts or title changes
}, [title]);  // `fetchMostRecentResult` needs to be wrapped in useCallback or defined inside useEffect if it uses `title`




return (
  <div className={`config-card ${cardColor}`} onClick={navigateToReconciliationPage}>
      <div className="config-card-header">
          <h2 className="config-card-title">{title}</h2>
          {loading && <span className="loading-indicator">Loading...</span>} {/* Styled loading indicator next to the title */}
      </div>

      <div className="config-card-status">
          <div className="config-card-type">{formatType(type)}</div>
          {statusMessage}
      </div>

      <div className="config-card-checkmarks">
          {fileIcons}
      </div>

      <button onClick={handleActionButtonClick} className="config-card-action-button">
          {actionButtonLabel}
      </button>

      <div className="config-card-data">
        {loading ? (
          <div className="loading-data">Loading data...</div> 
        ) : (
          <>
            <div className="config-data-item">
              <BsCheck2Circle />
              {formattedMatchedPercentage}
            </div>
            <div className="config-data-item">
              <FiAlertCircle />
              {highestUnmatchedAmount}
            </div>
            <div className="config-data-item">
              <FiClock />
              {formatTimestamp(mostRecentResult?.timestamp)}
            </div>
          </>
        )}
      </div>
  </div>
);


}

export default HomeConfigCard;