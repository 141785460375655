import React, { useState, useEffect } from 'react';
import { Auth, API } from 'aws-amplify';
import { Link  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import HomeConfigCard from './HomeConfigCard'; 
import './HomePage.css'; 
import './bmheader.css';
import ComprehensiveMetricsDisplay from './ComprehensiveMetricsDisplay';
import { TfiReload } from 'react-icons/tfi';
import { FaFilter } from "react-icons/fa";
import Header from './Header'; 


function HomePage() {
  const [allData, setAllData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [allFileLists, setAllFileLists] = useState([]);
  const [comprehensiveMetrics, setComprehensiveMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState('All'); // Added filter state
  const [filenames, setFilenames] = useState([]);
  const [configStatuses, setConfigStatuses] = useState({});
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
const [selectedStatuses, setSelectedStatuses] = useState({});




const navigate = useNavigate();


  const updateConfigStatus = (title, status) => {
    setConfigStatuses(prevStatuses => ({
        ...prevStatuses,
        [title]: status
    }));
};

// In HomePage.js

useEffect(() => {
  const statusCounts = {
    "Files Missing": 0,
    "Files Present": 0,
    "Recon Successful": 0,
    "To Review": 0
  };

  Object.values(configStatuses).forEach(status => {
    if (statusCounts.hasOwnProperty(status)) {
      statusCounts[status]++;
    }
  });

  // Now pass statusCounts to ComprehensiveMetricsDisplay
  // For example, set it in a state or pass directly as a prop
}, [configStatuses]);





  const fetchAllConfigData = async () => {
    setIsLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const organization = user.attributes['custom:organization'];
      const response = await API.post('ConfigData', '/allconfigdata', { body: { organization } });
      
  
      setAllData(response);
      setFilteredData(response);
  
      // Extract and store filenames along with titles
      const extractedFilenames = response.map(item => ({
        title: item.title,
        filename1: item.filename1,
        filename2: item.filename2
      }));
  
      setFilenames(extractedFilenames);
    } catch (err) {
      
    }
    setIsLoading(false);
  };
  
  
  


  const fetchAllFiles = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const organization = user.attributes['custom:organization'];
  
      // The name of the API and the path specified for fetching files from S3
      const apiName = 'FetchS3Files';
      const path = '/fetchs3files';
      
      // Making the POST request to the API Gateway with the organization in the request body
      const response = await API.post(apiName, path, {
        body: {
            title: 'FetchAllFilesX5787D',
            organization
        }
    });
  
     
  
      // Extracting the files array from the response object
      const filesArray = response.files;
      
      // Check if the extracted filesArray is an array
      if (Array.isArray(filesArray)) {
        const fileKeys = filesArray.map(file => file.key);
    
        setAllFileLists(fileKeys); // Set the state with the file keys
      } else {
      
      }
    } catch (err) {
     
    }
  };
  

const fetchComprehensiveMetrics = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const organization = user.attributes['custom:organization'];
    const body = {
      type: filter,
      organization: organization
    };
    const metricsData = await API.post('ComprehensiveMetrics', '/compmetrics', { body });
   
    setComprehensiveMetrics(metricsData);
  } catch (err) {
   
  }
};

const [fadeState, setFadeState] = useState("in");
useEffect(() => {
  setFadeState("out");
  const timeoutId = setTimeout(() => {
      if (filter === 'All') {
          setFilteredData(allData);
      } else {
          setFilteredData(allData.filter(data => data.type === filter));
      }
      setFadeState("in");
  }, 500); // Matches the 0.5s transition duration

  return () => clearTimeout(timeoutId); // Cleanup timer
}, [filter, allData]);

const [searchTerm, setSearchTerm] = useState("");
const filteredConfigs = (filteredData && filteredData.filter(config => 
  config.title.toLowerCase().includes(searchTerm.toLowerCase())
)) || [];




useEffect(() => {
  fetchAllFiles();
  fetchAllConfigData(); // Updated function name
}, []);



useEffect(() => {
 
  fetchComprehensiveMetrics();
}, [filter]);


useEffect(() => {
  if (filter === 'All') {
    setFilteredData(allData);
  } else {
    setFilteredData(allData.filter(data => data.type === filter));
  }
}, [filter, allData]);

useEffect(() => {
  // Extract unique status types from configStatuses
  const uniqueStatuses = new Set(Object.values(configStatuses));
  // Initialize selectedStatuses with these unique statuses
  const initialStatuses = {};
  uniqueStatuses.forEach(status => {
    initialStatuses[status] = true; // Initially, all statuses are selected
  });
  setSelectedStatuses(initialStatuses);
}, [configStatuses]);


const applyStatusFilter = () => {
  setIsFilterModalOpen(false);
  const filtered = allData.filter(data => {
    const status = configStatuses[data.title]; // Get status of the config card
    return selectedStatuses[status]; // Check if this status is selected
  });
  setFilteredData(filtered);
};

if (isLoading) {
  return (
    <div className="loading">
      <div className="ai-animation">
        <div className="circle circle-1"></div>
        <div className="circle circle-2"></div>
      </div>
      Loading reconciliations...
    </div>
  );
}

return (
  <div className="parent-container">
    <Header />

    <div className="tab-container">
      <button className={`tab ${filter === 'All' ? 'tab-active' : ''}`} onClick={() => setFilter('All')}>All</button>
      <button className={`tab ${filter === 'trade' ? 'tab-active' : ''}`} onClick={() => setFilter('trade')}>Trade</button>
      <button className={`tab ${filter === 'position' ? 'tab-active' : ''}`} onClick={() => setFilter('position')}>Position</button>
      <button className={`tab ${filter === 'cash' ? 'tab-active' : ''}`} onClick={() => setFilter('cash')}>Cash</button>
      <button className={`tab ${filter === 'bank' ? 'tab-active' : ''}`} onClick={() => setFilter('bank')}>Bank</button>
      <button className={`tab ${filter === 'fee' ? 'tab-active' : ''}`} onClick={() => setFilter('fee')}>Fee</button>
      <button className={`tab ${filter === 'other' ? 'tab-active' : ''}`} onClick={() => setFilter('other')}>Other</button>
    </div>

    <div className={`content-container ${fadeState === "out" ? "content-fade-out" : ""}`}>
      <div className="comp-container">
      <ComprehensiveMetricsDisplay 
  comprehensiveMetrics={comprehensiveMetrics}
  configStatuses={configStatuses} // Assuming you've set this in state or calculated it
/>
      </div>

      <div className="configs-section">

<div className="config-search-container">
  <div className="left-group">
    <input 
      type="text" 
      placeholder="Search for a config..." 
      value={searchTerm} 
      onChange={e => setSearchTerm(e.target.value)} 
      className="config-search-input"
    />

  
  {/* Filter button */}
  <button onClick={() => setIsFilterModalOpen(prev => !prev)} className="filter-button">
        <FaFilter />
      </button>
    </div>

    {isFilterModalOpen && (
      <div className={`filter-modal ${isFilterModalOpen ? 'filter-modal-active' : ''}`}>
      {Object.keys(selectedStatuses).map(status => (
        <div key={status}>
          <input
            type="checkbox"
            id={`checkbox-${status}`} /* Unique ID for each checkbox */
            checked={selectedStatuses[status]}
            onChange={() => {
              setSelectedStatuses(prev => ({
                ...prev,
                [status]: !prev[status]
              }));
            }}
          />
          <label htmlFor={`checkbox-${status}`}>{status}</label> {/* Label for each checkbox */}
        </div>
      ))}
      <button onClick={applyStatusFilter} className="filter-modal-apply">
        Apply
      </button>
    </div>
)}



          <div className="config-actions">
            <button onClick={fetchAllConfigData} className="refresh-button">
              <TfiReload size={24} />
            </button>
            <div className="config-count">{filteredConfigs.length}</div>
            <Link to="/config/new" className="create-config-button">Create New Config</Link>
          </div>
        </div>

        <div className="configs-container">
          {Array.isArray(filteredConfigs) && filteredConfigs.length > 0 ? (
            filteredConfigs.map((config) => {
              // Find corresponding filenames for each config
              const correspondingFilenames = filenames.find(f => f.title === config.title) || {};
              return (
                <HomeConfigCard
                  key={config.title}
                  title={config.title}
                  type={config.type}
                  filename1={correspondingFilenames.filename1}
                  filename2={correspondingFilenames.filename2}
                  allFileLists={allFileLists}
                  filter={filter}
                  updateConfigStatus={updateConfigStatus}
                />
              );
            })
          ) : (
            <div className="welcome-card-container">
            <div className="welcome-card">
              <h2>Welcome to Fast Recon</h2>
              <p>Your no-code data reconciliation solution</p>
            </div>
            <div className="welcome-card" onClick={() => navigate('/config/new')}>
              <h2>Click here</h2>
              <p>to create your first reconciliation</p>
            </div>
            
          </div>
          
          )}
        </div>
      </div>
    </div>
  </div>
);

}

export default HomePage;