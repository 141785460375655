import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './ComprehensiveMetricsDisplay.css' 

ChartJS.register(ArcElement, Tooltip);
function ComprehensiveMetricsDisplay({ comprehensiveMetrics, configStatuses }) {
    const totalEntries = comprehensiveMetrics?.metrics?.["Number of Entries"] || 1;
    const matchedAmount = comprehensiveMetrics?.metrics?.["Matched Amount"] || 0;
    const unmatchedAmount = comprehensiveMetrics?.metrics?.["Unmatched Amount"] || 0;

    const matchedPercentage = ((matchedAmount / totalEntries) * 100).toFixed(2);
    const unmatchedPercentage = ((unmatchedAmount / totalEntries) * 100).toFixed(2);

    const valueOfBreaks = comprehensiveMetrics?.break_counts?.reduce((total, item) => total + parseFloat(item['value of breaks'] || 0), 0) || 0;

    const calculateStatusCounts = () => {
        const counts = {};
        Object.values(configStatuses).forEach(status => {
            counts[status] = (counts[status] || 0) + 1;
        });
        return counts;
    };

    const statusCounts = calculateStatusCounts();

    const getValueOfBreaksByCategory = (category) => comprehensiveMetrics?.break_counts?.reduce((total, item) => {
        if (item.column_name.toLowerCase() === category.toLowerCase()) {
            return total + parseFloat(item['value of breaks'] || 0);
        }
        return total;
    }, 0) || 0;

    const valueOfPriceBreaks = getValueOfBreaksByCategory('price');
    const valueOfMatchedPrice = getValueOfBreaksByCategory('matched_df');
   

    const matchedChartData = {
        labels: ['Matched', 'Unmatched'],
        datasets: [{
            data: [matchedAmount, unmatchedAmount], // Use actual amounts
            backgroundColor: ['#1a9338', '#e7f138'],
        }],
    };

    const matchedChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        cutout: '75%',
        plugins: {
          datalabels: {
            display: true,
            color: 'white',
            formatter: (value) => value > 0 ? value : null,
          }
        }
      };

      const statusColors = {
        'To Review': '#ff0000', // Red for "To Review", indicating urgent attention is needed
        'Files Present': '#ffff00', // Yellow for "Files Present", indicating caution or to denote presence
        'Files Missing': '#808080', // Gray for "Files Missing", suggesting neutrality or absence
        'Recon Successful': '#008000' // Green for "Reconciled", symbolizing completion and success
    };
    

    const statusChartData = {
        labels: Object.keys(statusCounts),
        datasets: [{
            data: Object.values(statusCounts),
            backgroundColor: Object.keys(statusCounts).map(status => statusColors[status] || '#999999'),
        }],
    };

    const statusChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        cutout: '75%',
        plugins: {
          datalabels: {
            display: true,
            color: 'white',
            formatter: (value) => value > 0 ? value : null,
          }
        }
      };

        // Styling for chart and status display, assuming use of similar styles as ReconciliationMetricsDisplay
        const chartStyle = {
            width: '120px',
            height: '120px'
        };

        


        return (
            <div className="comprehensive-metrics-display">
            {/* Matched Chart Display with Data Labels on the Right */}
            <div className="metric-column">
                <h3 className="metric-title">Matched</h3>
                <div className="chart-and-stats">
                    <div className="metric-content" style={chartStyle}>
                        <Doughnut data={matchedChartData} options={matchedChartOptions} />
                    </div>
                    <div className="chart-stats" style={{ marginLeft: 'auto' }}>
                    {matchedAmount > 0 && <p>Matched: {matchedAmount}</p>}
                    {unmatchedAmount > 0 && <p>Unmatched: {unmatchedAmount}</p>}
                    </div>
                </div>
            </div>
        
                {/* Today's Breaks */}
                <div className="metric-column">
                    <h3 className="metric-title">Today's Breaks</h3>
                    <div className="metric-content">
                    {unmatchedAmount > 0 && <p>Unmatched: {unmatchedAmount}</p>}
                    </div>
                </div>
        
                {/* Value of Specific Breaks Display */}
                <div className="metric-column">
                    <h3 className="metric-title">Value of Today's Breaks</h3>
                    <div className="metric-content">
                        {valueOfPriceBreaks > 0 && <p>${valueOfPriceBreaks.toFixed(2)}</p>}
                    </div>
                </div>

                                {/* Total Value Reconcilied  */}
                                <div className="metric-column">
                    <h3 className="metric-title">Total Value Reconcilied</h3>
                    <div className="metric-content">
                    {valueOfMatchedPrice > 0 && <p>${valueOfMatchedPrice.toFixed(2)}</p>}
                    </div>
                </div>
               
        
        
        
   {/* Status of Reconciliations with Chart on the Right and Data on the Left */}
   <div className="metric-column">
            <h3 className="metric-title">Status of Reconciliations</h3>
            <div className="chart-and-stats">
                <div className="chart-stats" style={{ marginRight: '20px' }}>
                    {Object.entries(statusCounts).map(([status, count]) => (
                        <p key={status}>{status}: {count}</p>
                    ))}
                </div>
                {/* Chart */}
                <div className="metric-content" style={{ marginLeft: 'auto', ...chartStyle }}>
                    <Doughnut data={statusChartData} options={statusChartOptions} />
                </div>
            </div>
        </div>
    </div>







);
}
export default ComprehensiveMetricsDisplay;
